
<template>
  <div class="container">
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">طلبات الاستقاله</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        
        
        <table class="datatables-basic table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>رقم المستند</th>
                  <th>الموظف</th>
                  <th>تاريخ التقديم </th>
                  <th>الاسباب</th>
                  <td>المدير المباشر</td>
                  <th>الموارد البشريه</th>
                  <td>المدير العام</td>
                  <th class="action">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.username }} </td>
                <td> {{ item.time }}  </td>
                <td> {{ item.reasons }}  </td>
                <td> 
                  <span v-if="item.direct_manager == 'اعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span>                 
                  {{ item.direct_manager_reason }}
                </td>
                <td> 
                  <span v-if="item.hr == 'اعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'اعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>                 
                  {{ item.general_director_reason }}
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.author, item.reasons, item.hr, item.hr_reason, item.direct_manager, item.direct_manager_reason, item.general_director, item.general_director_reason, item.acount_type)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
    </div>


    




    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <div class="form-floating form-floating-outline mb-4">
                  <textarea class="form-control" v-model="reasons" cols="30" rows="10" placeholder=""></textarea>
                  <label for="html5-date-input"> سبب الاستقالة </label>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Edit Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id">

              <div v-if="edit_author == user_id" class="form-floating form-floating-outline mb-4">
                  <textarea class="form-control" v-model="edit_reasons" cols="5" rows="5"></textarea>
                  <label for="html5-date-input">اسباب الاستقالة</label>
              </div>

              <div v-html="this.getDataFromMuazaf" class=""></div>


              <div class="row" v-if="acount_type == 'direct_manager'">
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                  <select class="form-select" v-model="edit_direct_manager">
                    <option value="">حالة الطلب</option>
                    <option value="اعتماد">اعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <textarea class="form-control" cols="3" rows="3" v-model="edit_direct_manager_reason" placeholder=""></textarea>
                    <label for="html5-date-input">سبب الرفض ان وجد</label>
                </div>
              </div>


              <div v-if="employee_acount_type == 5  && edit_direct_manager == 'اعتماد'">
                <div class="row" v-if="acount_type == 'hr'">
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <select class="form-select" v-model="edit_hr">
                        <option value="">حالة الطلب</option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <textarea class="form-control" cols="3" rows="3" v-model="edit_hr_reason" placeholder=""></textarea>
                      <label for="html5-date-input">سبب الرفض ان وجد</label>
                  </div>
                </div>
              </div>

              <div v-if="employee_acount_type < 5">
                <div class="row" v-if="acount_type == 'hr'">
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <select class="form-select" v-model="edit_hr">
                        <option value="">حالة الطلب</option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <textarea class="form-control" cols="3" rows="3" v-model="edit_hr_reason" placeholder=""></textarea>
                      <label for="html5-date-input">سبب الرفض ان وجد</label>
                  </div>
                </div>
              </div>



              <div v-if="employee_acount_type == 5  && edit_hr == 'اعتماد'">
                <div class="row" v-if="acount_type == 'general_director'">
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <select class="form-select" v-model="edit_general_director">
                        <option value="">حالة الطلب</option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <textarea class="form-control" cols="3" rows="3" v-model="edit_general_director_reason" placeholder=""></textarea>
                      <label for="html5-date-input">سبب الرفض ان وجد</label>
                  </div>
                </div>
              </div>

              <div v-if="employee_acount_type < 5">
                <div class="row" v-if="acount_type == 'general_director'">
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <select class="form-select" v-model="edit_general_director">
                        <option value="">حالة الطلب</option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <textarea class="form-control" cols="3" rows="3" v-model="edit_general_director_reason" placeholder=""></textarea>
                      <label for="html5-date-input">سبب الرفض ان وجد</label>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Edit Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.card .action {
  width: 115px;
}
</style>



<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopUsers: [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),
      user_id : localStorage.getItem('user_id'),

      getDataFromMuazaf: "",

      reasons	: "",
   

      edit_id        : "",
      edit_author   : "",
      edit_reasons	 : "",
      edit_hr        : "",
      edit_hr_reason : "",
      edit_direct_manager : "",
      edit_direct_manager_reason : "",
      edit_general_director : "",
      edit_general_director_reason : "",
      employee_acount_type : "",

      
    }
  },

  mounted () {
      window.document.title = "طلب استقاله";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    acceptRow(id, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'resignation',
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/resignation`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
          this.loopUsers = res.data.users
        })
    
    },

    store  () {
      axios.post(`${urlSite}/store.resignation`, {
        author        : localStorage.getItem("user_id"),
        muazaf	        : localStorage.getItem('user_id'),
        reasons	        : this.reasons,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    

    // Get Data For Edit
    openEditModel (id, author, reasons, hr, hr_reason, direct_manager, direct_manager_reason, general_director, general_director_reason, acount_type) {
      this.edit_id             = id
      this.edit_author              = author
      this.edit_reasons	       = reasons
      this.edit_hr                      = hr
      this.edit_hr_reason               = hr_reason
      this.edit_direct_manager          = direct_manager
      this.edit_direct_manager_reason   = direct_manager_reason
      this.edit_general_director        = general_director
      this.edit_general_director_reason = general_director_reason
      this.employee_acount_type         = acount_type
    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.resignation`, {
        id	            : this.edit_id,
        reasons	        : this.edit_reasons,
        hr              : this.edit_hr,
        hr_reason       : this.edit_hr_reason,
        direct_manager  : this.edit_direct_manager,
        direct_manager_reason   : this.edit_direct_manager_reason,
        general_director: this.edit_general_director,
        general_director_reason : this.edit_general_director_reason,
        acount_type : localStorage.getItem('acount_type'),
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "resignation", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   