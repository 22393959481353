
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header"> وقت اضافي </h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>الموظف </th>
                <th>مدير القسم</th>
                <th>الفترة</th>
                <th>التاريخ</th>
                <th>وذلك للاسباب التاليه</th>
                <th>المدير العام</th>
                <th>الموارد البشرية</th>
                <th>ملاحظات</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>

                <td> {{ item.head_department }}  </td>
                <td> {{ item.period }}  </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.reasons }}  </td>
                <td> 
                  <select v-if="acount_type == 'general_director'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'general_director')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  <button  v-if="item.reason_refuse == null && acount_type == 'general_director'" class="btn btn-primary btn-new btn-xs"
                          data-toggle="modal"
                          @click="getID(item.id)" 
                          data-target="#editReasons">
                          سبب الرفض
                  </button>
                  {{ item.reason_refuse }}
                </td>
                <td> 
                  <div v-if="item.acount_type == 5 && item.general_director == 'تم الاعتماد'">
                    <select v-if="acount_type == 'hr'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'hr')">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                    </select>
                  </div>
                  <div v-if="item.acount_type < 5">
                    <select v-if="acount_type == 'hr'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'hr')">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                    </select>
                  </div>

                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>                 
                </td>
                <td> {{ item.note }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.muazaf, item.head_department, item.period, item.date, item.reasons, item.note)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> مدير القسم </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="head_department" class="form-control"  placeholder="يرجي كتابة البريد الالكتروني">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الفترة </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="period" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> التاريخ  </label>
                  <div class="col-sm-9">
                    <input type="date" v-model="date" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">وذلك للاسباب التاليه</label>
                  <div class="col-sm-9">
                    <textarea v-model="reasons" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">ملاحظات</label>
                  <div class="col-sm-9">
                    <textarea v-model="note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Update Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="edit_id" >

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="edit_muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> مدير القسم </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_head_department" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الفترة </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_period" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> التاريخ  </label>
                  <div class="col-sm-9">
                    <input type="date" v-model="edit_date" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">وذلك للاسباب التاليه</label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_reasons" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">ملاحظات</label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->






    
    <!-- editReasons Row -->
    <div class="modal fade" id="editReasons" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="updateReasons()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="editID" >

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> سبب الرفض </label>
                  <div class="col-sm-9">
                    <textarea v-model="reason_refuse" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- editReasons Row -->


  </div> <!-- container -->   
</template>





<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopUsers: [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),

      muazaf : null,
      head_department: null,
      period: null,
      date: null,
      reasons: null,
      reason_refuse: null,
      note: null,

      
      // Edit Row
      editID: "",
      edit_id: null,
      edit_muazaf : null,
      edit_head_department: null,
      edit_period: null,
      edit_date: null,
      edit_reasons: null,
      edit_reason_refuse: null,
      edit_note: null,
      
    }
  },

  mounted () {
      window.document.title = "وقت اضافي";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getID(id) {
      this.editID = id
    },
    

    updateReasons() {
      axios.post(`${urlSite}/update.overtime.reason.refuse`, {
        id: this.editID,
        reason_refuse : this.reason_refuse,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },


    acceptRow(id, muazaf, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'overtime',
        muazaf: muazaf,
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    update(id, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'overtime',
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/overtime`)
.then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
          this.loopUsers = res.data.users
        })
    
    },




    store  () {
      axios.post(`${urlSite}/store.overtime`, {
        author        : localStorage.getItem("user_id"),
        muazaf            : this.muazaf,
        head_department   : this.head_department,
        period            : this.period,
        reasons           : this.reasons,
        date              : this.date,
        note              : this.note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },



    // Get Data For Edit
    openEditModel (id, muazaf, head_department, period, date, reasons, note) {
      this.edit_id      = id,
      this.edit_muazaf  = muazaf,
      this.edit_head_department = head_department,
      this.edit_period  = period,
      this.edit_date    = date,
      this.edit_reasons = reasons
      this.edit_note    = note
    }, // openEditModel


     

    update () {
      axios.post(`${urlSite}/update.overtime`, {
        id                :this.edit_id, 
        muazaf            : this.edit_muazaf,
        head_department   : this.edit_head_department,
        period            : this.edit_period,
        reasons           : this.edit_reasons,
        date              : this.edit_date,
        note              : this.edit_note,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "overtime", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   