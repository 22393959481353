
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">العقــــــــوبات</h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>مقدم الطلب</th>
                <th>الموظف</th>
                <th>سبب العقوبه</th>
                <th>العقوبه</th>
                <!--
                <td>المدير المباشر</td>
                -->
                <th>الموارد البشريه</th>
                <td>المدير العام</td>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.author">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.reason_punishment }}  </td>
                <td> {{ item.hr_punishment }}  </td>
                <td> 
                  <span v-if="item.hr == 'اعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}     
                </td>
                <td> 
                  <span v-if="item.general_director == 'اعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span><br>
                  {{ item.reason_gd }}
                </td>
                <td> {{ item.note }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.author, item.muazaf, item.punishment, item.reason_punishment, item.hr, item.hr_punishment, item.hr_reason, item.direct_manager, item.direct_manager_name, item.general_director, item.reason_gd, item.direct_manager_reason, item.acount_type)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="muazaf" required class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اسباب العقوبه</label>
                  <div class="col-sm-9">
                    <textarea v-model="reason_punishment" required class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>
              <!--
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المدير المباشر</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="direct_manager_name" class="form-control" />
                  </div>
              </div>
              -->

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Edit Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="edit_id" />

              <div v-if="author == author_id">
                
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="edit_muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اسباب العقوبه</label>
                    <div class="col-sm-9">
                      <textarea v-model="edit_reason_punishment" class="form-control" cols="3" rows="3"></textarea>
                    </div>
                </div>
              </div>

              <div v-if="acount_type == 'hr'">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد  الموارد البشرية</label>
                  <div class="col-sm-9">
                    <select v-model="edit_hr" class="form-control form-select">
                      <option value=""></option>
                      <option value="اعتماد">اعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">العقوبه</label>
                    <div class="col-sm-9">
                      <textarea v-model="edit_hr_punishment" class="form-control" cols="3" rows="3" placeholder="العقوبه في حالة الاعتمادة علي الطلب"></textarea>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض</label>
                    <div class="col-sm-9">
                      <textarea v-model="edit_hr_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                    </div>
                </div>
              </div>


              <div v-if="employee_acount_type == 5  && edit_hr == 'اعتماد'">
                <div v-if="acount_type == 'general_director'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد المدير العام</label>
                    <div class="col-sm-9">
                      <select v-model="edit_general_director" class="form-control form-select">
                        <option value=""></option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_reason_gd" class="form-control form-select" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>

              <div v-if="employee_acount_type < 5">
                <div v-if="acount_type == 'general_director'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد المدير العام</label>
                    <div class="col-sm-9">
                      <select v-model="edit_general_director" class="form-control form-select">
                        <option value=""></option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_reason_gd" class="form-control form-select" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>





<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopUsers: [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),
      author      : localStorage.getItem("user_id"),

      muazaf	              : "",
      punishment	          : "",
      reason_punishment	    : "",
      hr_punishment	        : "",
      hr_reason	            : "",
      direct_manager	      : "",
      general_director	    : "",
      direct_manager_reason : "",

      author_id             : null,
      edit_id               : "",
      edit_muazaf           : "",
      edit_punishment     	: "",
      edit_reason_punishment	: "",
      edit_hr	              : "",
      edit_hr_punishment	  : "",
      edit_hr_reason	      : "",
      edit_direct_manager	  : "",
      edit_direct_manager_name	: "",
      edit_general_director	: "",
      edit_reason_gd	      : "",
      edit_direct_manager_reason	: "",
      employee_acount_type  : "",
      
    }
  },

  mounted () {
      window.document.title = "العقــــــــوبات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    acceptRow(id, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'punishment',
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/punishment`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
          this.loopUsers = res.data.users

        })
    
    },




    store  () {
      axios.post(`${urlSite}/store.punishment`, {
        author              : localStorage.getItem("user_id"),
        muazaf	            : this.muazaf,
        reason_punishment   : this.reason_punishment,
        direct_manager_name : this.direct_manager_name
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },




    


    // Get Data For Edit
    openEditModel (id, author, muazaf, punishment, reason_punishment, hr, hr_punishment, hr_reason, direct_manager, direct_manager_name, general_director, reason_gd, direct_manager_reason, acount_type) {
      this.edit_id                    = id
      this.author_id                  = author
      this.edit_muazaf	              = muazaf
      this.edit_punishment            = punishment
      this.edit_reason_punishment     = reason_punishment
      this.edit_hr                    = hr
      this.edit_hr_punishment         = hr_punishment
      this.edit_hr_reason             = hr_reason
      this.edit_direct_manager        = direct_manager
      this.edit_direct_manager_name   = direct_manager_name
      this.edit_general_director      = general_director
      this.edit_reason_gd             = reason_gd
      this.edit_direct_manager_reason = direct_manager_reason
      this.employee_acount_type       = acount_type

    }, // openEditModel



    update () {
      axios.post(`${urlSite}/update.punishment`, {
        id	                : this.edit_id,
        muazaf	            : this.edit_muazaf,
        punishment	        : this.edit_punishment,
        reason_punishment	  : this.edit_reason_punishment,
        hr	                : this.edit_hr,
        hr_punishment	      : this.edit_hr_punishment,
        hr_reason	          : this.edit_hr_reason,
        direct_manager	    : this.edit_direct_manager,
        direct_manager_reason	  : this.edit_direct_manager_reason,
        general_director	  : this.edit_general_director,
        reason_gd	          : this.edit_reason_gd,
        acount_type         : localStorage.getItem('acount_type'),

      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "punishment", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   