
<template>
  <div class="container">
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">طلبات الانتداب</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>


          <table class="datatables-basic table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info" style="width: 1396px;">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>رقم المستند</th>
                  <th>الموظف</th>
                  <th>الوظيفه</th>
                  <th>القسم</th>
                  <th>وسيله السفر</th>
                  <th>عنوان الانتداب</th>
                  <th>بيانات المهمة</th>
                  <th>المدير المباشر</th>
                  <th>الموارد البشرية</th>
                  <th>المدير العام</th>
                  <th style="width: 200px;">تاريخ التقديم </th>
                  <th class="action">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <router-link to="#" v-if="employee.id == item.muazaf">{{ employee.name }}</router-link>
                  </span>   
                </td>
                <td> {{ item.job }}  </td>
                <td> {{ item.category }}  </td>
                <td> {{ item.travel }}  </td>
                <td> {{ item.address }}  </td>
                <td> {{ item.task }}  </td>
                <td>
                  <span v-if="item.direct_manager == 'اعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span><br>
                  {{ item.direct_manager_reason }}
                </td>
                <td>
                  <span v-if="item.hr == 'اعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> 
                  {{ item.hr_reason }}
                </td>
                <td>
                  <span v-if="item.general_director == 'اعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> 
                  {{ item.general_director_reason }}
                </td>
                <td> {{ item.time }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id,
                                            item.author,
                                            item.muazaf,
                                            item.job,
                                            item.category,
                                            item.task,
                                            item.travel,
                                            item.address,
                                            item.direct_manager,
                                            item.direct_manager_reason,
                                            item.hr,
                                            item.hr_reason,
                                            item.general_director,
                                            item.general_director_reason,
                                            item.acount_type
                                          )"> 
                      
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    	







    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="text" id="html5-date-input" v-model="job" placeholder="">
                    <label for="html5-date-input">الوظيفة</label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <select class="form-control" v-model="category">
                      <option v-for="item in loopDirectManager" :key="item" :value="item.id">{{ item.name }}</option>
                    </select>
                    <label for="html5-date-input"> القسم </label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" id="exampleFormControlSelect1" v-model="travel">
                    <option>وسيلة السفر</option>
                    <option value="جوا">جوا</option>
                    <option value="برا">برا</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="text" id="html5-date-input" v-model="address" placeholder="">
                    <label for="html5-date-input"> عنوان الامتداب </label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <textarea class="form-control" v-model="task" cols="30" rows="10"></textarea>
                    <label for="html5-date-input">بيانات مهمه العمل المنتدب لها</label>
                </div>
              </div>
              					




            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Edit Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id">


              <div v-if="author == user_id" class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="text" id="html5-date-input" v-model="edit_job" placeholder="">
                    <label for="html5-date-input">الوظيفة</label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="text" id="html5-date-input" v-model="edit_category" placeholder="">
                    <label for="html5-date-input"> القسم </label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" id="exampleFormControlSelect1" v-model="edit_travel">
                    <option>وسيلة السفر</option>
                    <option value="جوا">جوا</option>
                    <option value="برا">برا</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="text" id="html5-date-input" v-model="edit_address" placeholder="">
                    <label for="html5-date-input"> عنوان الامتداب </label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <textarea class="form-control" v-model="edit_task" cols="30" rows="10"></textarea>
                    <label for="html5-date-input">بيانات مهمه العمل المنتدب لها</label>
                </div>
              </div> <!-- End Mozaf -->



              <div class="row" v-if="acount_type == 'direct_manager'">
                <strong>اعتماد المدير المباشر</strong>
                <br><br>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                  <select class="form-select" v-model="edit_direct_manager">
                    <option value="">حالة الطلب</option>
                    <option value="اعتماد">اعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <textarea class="form-control" cols="3" rows="3" v-model="edit_direct_manager_reason" placeholder=""></textarea>
                    <label for="html5-date-input">سبب الرفض ان وجد</label>
                </div>
              </div>

              <div v-html="this.getDataFromMuazaf" class=""></div>
              
              <div v-if="employee_acount_type == 5  && edit_direct_manager == 'اعتماد'">
                <div class="row" v-if="acount_type == 'hr'">
                  <strong>اعتماد الموارد البشرية</strong>
                  <br><br>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <select class="form-select" v-model="edit_hr">
                        <option value="">حالة الطلب</option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <textarea class="form-control" cols="3" rows="3" v-model="edit_hr_reason" placeholder=""></textarea>
                      <label for="html5-date-input">سبب الرفض ان وجد</label>
                  </div>
                </div>
              </div>
              <div v-if="employee_acount_type < 5">
                <div class="row" v-if="acount_type == 'hr'">
                  <strong>اعتماد الموارد البشرية</strong>
                  <br><br>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <select class="form-select" v-model="edit_hr">
                        <option value="">حالة الطلب</option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <textarea class="form-control" cols="3" rows="3" v-model="edit_hr_reason" placeholder=""></textarea>
                      <label for="html5-date-input">سبب الرفض ان وجد</label>
                  </div>
                </div>
              </div>
              


              <div v-if="employee_acount_type == 5  && edit_hr == 'اعتماد'">
                <div class="row" v-if="acount_type == 'general_director'">
                  <strong>اعتماد المدير العام</strong>
                  <br><br>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <select class="form-select" v-model="edit_general_director">
                        <option value="">حالة الطلب</option>
                        <option value="اعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <textarea class="form-control" cols="3" rows="3" v-model="edit_general_director_reason" placeholder=""></textarea>
                      <label for="html5-date-input">سبب الرفض ان وجد</label>
                  </div>
                </div>
              </div>
              <div v-if="employee_acount_type < 5">
                  <div class="row" v-if="acount_type == 'general_director'">
                  <strong>اعتماد المدير العام</strong>
                    <br><br>
                    <div class="form-floating form-floating-outline mb-4 col-md-12">
                      <select class="form-select" v-model="edit_general_director">
                          <option value="">حالة الطلب</option>
                          <option value="اعتماد">اعتماد</option>
                          <option value="رفض">رفض</option>
                      </select>
                    </div>
                    <div class="form-floating form-floating-outline mb-4 col-md-12">
                        <textarea class="form-control" cols="3" rows="3" v-model="edit_general_director_reason" placeholder=""></textarea>
                        <label for="html5-date-input">سبب الرفض ان وجد</label>
                    </div>
                  </div>
                </div>
              


              



            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.card .action {
  width: 115px;
}
</style>



<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopUsers: [],
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopDirectManager:[],
      acount_type : localStorage.getItem('acount_type'),
      user_id     : localStorage.getItem("user_id"),
      getDataFromMuazaf: "",

      author : "",
      muazaf : "",
      job : "",
      category : "",
      task : "",
      travel : "",
      address : "",

      edit_id : "",
      edit_muazaf : "",
      edit_job : "",
      edit_category : "",
      edit_task : "",
      edit_travel : "",
      edit_address : "",

      edit_direct_manager	: "",
      edit_direct_manager_reason	: "",
      edit_hr	: "",
      edit_hr_reason	: "",
      edit_general_director	: "",
      edit_general_director_reason		: "",

      author : "",
      employee_acount_type: "",

      
    }
  },

  mounted () {
      window.document.title = "طلب الاجازة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    getData(){
      axios.get(`${urlSite}/work.assignment`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData     = res.data.data
          this.loopDirectManager = res.data.direct_manager
          this.loopUsers = res.data.users
        })
    
    },

    store  () {
      axios.post(`${urlSite}/store.work.assignment`, {
        author          : localStorage.getItem("user_id"),
        muazaf	        : this.muazaf,
        job	            : this.job,
        category	      : this.category,
        task	          : this.task,
        travel	        : this.travel,
        address	        : this.address,
      }).then(res => {
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
          this.getData();

      });

    },


  

    // Get Data For Edit
    openEditModel (id, author, muazaf, job, category, task, travel, address, direct_manager, direct_manager_reason, hr, hr_reason, general_director, general_director_reason, acount_type) {

      this.author         = author

      this.edit_id        = id
      this.edit_muazaf    = muazaf
      this.edit_job       = job
      this.edit_category  = category
      this.edit_task      = task
      this.edit_travel    = travel
      this.edit_address   = address

      this.edit_direct_manager	        = direct_manager
      this.edit_direct_manager_reason	  = direct_manager_reason
      this.edit_hr	                    = hr
      this.edit_hr_reason	              = hr_reason
      this.edit_general_director	      = general_director
      this.edit_general_director_reason = general_director_reason
      this.employee_acount_type         = acount_type

    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.work.assignment`, {
        id	            : this.edit_id,
        muazaf	        : this.edit_muazaf,
        job             : this.edit_job,
        category        : this.edit_category,
        task            : this.edit_task,
        travel          : this.edit_travel,
        address         : this.edit_address,

        direct_manager	         : this.edit_direct_manager,
        direct_manager_reason	   : this.edit_direct_manager_reason,
        hr	                     : this.edit_hr,
        hr_reason	               : this.edit_hr_reason,
        general_director	       : this.edit_general_director,
        general_director_reason  : this.edit_general_director_reason,


        acount_type : localStorage.getItem('acount_type'),


      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "work_assignment", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   