
<template>
  <div class="container">

    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">تدريب موظف</h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>التاريخ</th>
                <th>المهنه</th>
                <th>القسم الادارى</th>
                <th>التقييم الحالى للموظف</th>
                <th>التقييم بعد التدريب</th>
                <th>الاحتياج التدريبى له</th>
                <th>مدة التدريب</th>
                <th>مســــؤل التدريب</th>
                <th>الموارد البشريه</th>
                <td>المدير العام</td>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_training" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.occupation }}  </td>
                <td> {{ item.administrative_section }}  </td>
                <td> {{ item.evaluation_now }}  </td>
                <td> {{ item.evaluation_training }}  </td>
                <td> {{ item.training_need }}  </td>
                <td> {{ item.training_time }}  </td>
                <td> {{ item.training_officer }}  </td>
                <td> 
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.refuse_hr }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  {{ item.refuse_gd }}
                </td>
                <td> {{ item.note }}  </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">تسليم العهدة</h5>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info" style="width: 1396px;">
              <thead>
                <tr>
                  <th>#</th>
                  <th>رقم المستند</th>
                  <th>الموظف المسؤل</th>
                  <th>الموظف المستلم</th>
                  <th>الفرع</th>
                  <th>البيان</th>
                  <th>التوقيت</th>
                  <th>المحاسب</th>
                  <th>الموارد البشرية</th>
                  <th>المدير العام</th>
                  <th>ملاحظات</th>
                  <th>المرفقات</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_covenant" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <span v-if="employee.id == item.responsible_employee">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.branch }}  </td>
                <td> {{ item.statement }}  </td>
                <td> {{ item.time }}  </td>
                <td> 
                  <select  v-if="acount_type == 'financial_management'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'accountant')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.accountant == 'تم الاعتماد'" class="badge bg-label-success">{{ item.accountant }} </span>
                  <span v-if="item.accountant == 'رفض'" class="badge bg-label-danger">{{ item.accountant }} </span>   
                </td>
                <td> 
                  <select v-if="acount_type == 'hr'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'hr')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>  
                </td>
                <td> 
                  <select v-if="acount_type == 'general_director'"  class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'general_director')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>
                </td>
                <td> {{ item.note }}  </td>
                <td> 
                  <router-link :to="'/files/covenant/' + item.id">
                    المرفقات 
                  </router-link>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header"> وقت اضافي </h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>مدير القسم</th>
                <th>الفترة</th>
                <th>التاريخ</th>
                <th>وذلك للاسباب التاليه</th>
                <th>الموارد البشرية</th>
                <th>المدير العام</th>
                <th>ملاحظات</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_overtime" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.head_department }}  </td>
                <td> {{ item.period }}  </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.reasons }}  </td>
                <td> 
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>                 
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  {{ item.reason_refuse }}
                </td>
                <td> {{ item.note }}  </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    
    <br>
    <!-- Card Table -->
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">التامين الطبـــــــــى</h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>التاريخ المباشر</th>
                <th>شركة التامين</th>
                <th>فئة التامين</th>
                <td>المدير العام</td>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_insurance" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.tarikh_almubashirih }}  </td>
                <td> {{ item.insurance_company }}  </td>
                <td> {{ item.insurance_category }}  </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  {{ item.general_director_reason }}
                </td>
                <td> {{ item.note }}  </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header"> طلب سيـــــارة </h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>مهام العمل </th>
                <th>التاريخ</th>
                <th>بيانات السيارة</th>
                <th>مدير الموظف</th>
                <th>الموارد البشرية</th>
                <th>المدير العام</th>
                <th>ملاحظــــات</th>
                <th>المرفقات</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_CarRequest" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.stomach_data }}  </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.statement }} </td>
                <td> 
                  <span v-if="item.direct_manager == 'تم الاعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span>
                  <br>
                  {{ item.direct_manager_reason }}
                </td>
                <td> 
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>
                  <br>
                  {{ item.reason_refuse_hr }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>
                  <br>
                  {{ item.reason_refuse_gd}}
                </td>
                <td> {{ item.note }}  </td>
                <td> 
                  <router-link :to="'/files/car_request/' + item.id">
                    المرفقات 
                  </router-link>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">انتقال موظف بين الفروع</h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>اسم الموظف </th>
                <th>التاريخ</th>
                <th>المهنه</th>
                <th>المهنه الجديده</th>
                <th>الاداره الحاليه</th>
                <th>الاداره الجديدة</th>
                <th>العنوان الحالى</th>
                <th>العنوان الجديد</th>
                <th>الموظف</th>
                <th>المدير المباشر</th>
                <th>الموارد البشريه</th>
                <th>المدير العام</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_MoveToBranch" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.old_occupation }}  </td>
                <td> {{ item.new_occupation }}  </td>
                <td> 
                  <span v-for="adminis in loopDirectManager" :key="adminis">
                    <span v-if="adminis.id == item.old_administration">{{ adminis.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="adminis in loopDirectManager" :key="adminis">
                    <span v-if="adminis.id == item.new_administration">{{ adminis.name }}</span>
                  </span>   
                </td>

                <td> {{ item.current_address }}  </td>
                <td> {{ item.new_address }}  </td>
        

                <td> 
                  <span v-if="item.muazaf_approve == 'تم الاعتماد'" class="badge bg-label-success">{{ item.muazaf_approve }} </span>
                  <span v-if="item.muazaf_approve == 'رفض'" class="badge bg-label-danger">{{ item.muazaf_approve }} </span>  <br>
                  {{ item.muazaf_reason }}             
                </td>
                <td> 
                  <span v-if="item.direct_manager == 'تم الاعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span>  <br>
                  {{ item.direct_manager_reason }}             
                </td>
                <td> 
                  <span v-if="item.hr == 'old_administration'" class="badge bg-label-success">>تم ارسال الطلب للادارة الحالية</span>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}             
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span><br>
                  {{ item.general_director_reason }}             
                </td>
                <td> {{ item.note }}  </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">تقييم الموظفين</h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>تقييم الاداء</th>
                <th>تقييم الحضور والانصراف</th>
                <th>التعاون مع زملائه بالعمل </th>
                <th>تقييمات اخرى</th>
                <th>التوصيات</th>
                <th>التوقيت</th>
                <th> الموارد البشريه </th>
                <th>المدير العام </th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_StaffEvaluation" :key="item.name">
                <td> {{ index + 1  }} </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="scores in loopScores" :key="scores">
                    <span v-if="scores.id == item.employee_evaluation">{{ scores.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="scores in loopScores" :key="scores">
                    <span v-if="scores.id == item.absence">{{ scores.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="scores in loopScores" :key="scores">
                    <span v-if="scores.id == item.cooperating_workers">{{ scores.name }}</span>
                  </span>   
                </td>
                <td> {{ item.other_reviews }}  </td>
                <td> {{ item.recommendations }}  </td>
                <td> {{ item.time }}  </td>
                <td> 
                  <select v-if="acount_type == 'hr'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'hr')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.hr == 'تم المراجعه'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>                 
                </td>
                <td> 
                  <select v-if="acount_type == 'general_director'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'general_director')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>                  
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header"> طلبات الصيانه </h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>تفاصيل المعده</th>
                <th>بيان بالاعطال</th>
                <th>التاريخ</th>
                <th>مدير القسم</th>
                <th>الموارد البشرية</th>
                <th>المدير العام</th>
                <th>ملاحظــــات</th>
                <th>المرفقات</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_MaintenanceRequest" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.stomach_data }}  </td>
                <td> {{ item.statement }}  </td>
                <td> {{ item.date }}  </td>

                <td> 
                  <span v-if="item.direct_manager == 'تم الاعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span>
                  <br>
                  {{ item.reason_refuse_direct_manager }}
                </td>
                <td> 
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>
                  <br>
                  {{ item.reason_refuse_hr }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>
                  <br>
                  {{ item.reason_refuse_gd}}
                </td>
                <td> {{ item.note }}  </td>
                <td> 
                  <router-link :to="'/files/maintenance_request/' + item.id">
                    المرفقات 
                  </router-link>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">طلب الاجازة</h5>
            </div>
          </div>
        </div>
          <table class="datatables-basic table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>رقم المستند</th>
                  <th>تاريخ التقديم </th>
                  <th>بيانات الاجازه</th>
                  <th>نوع الاجازه</th>
                  <th>مدة الاجازه</th>
                  <th>البداية</th>
                  <th>النهاية</th>
                  <th>وسيلة السفر</th>
                  <th>الهاتف</th>
                  <th>العنوان</th>
                  <th>الموظف البديل</th>
                  <th>الايام المعتمده</th>
                  <th>المدير المباشر</th>
                  <th>الموارد البشرية</th>
                  <th>المدير العام</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_vacation" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.vacation_data }}  </td>
                <td> {{ item.vacation_type }}  </td>
                <td> {{ item.count_day }}  </td>
                <td> {{ item.start }}  </td>
                <td> {{ item.end }}  </td>
                <td> {{ item.travel }}  </td>
                <td> {{ item.phone_muazaf }}  </td>
                <td> {{ item.address_muazaf }}  </td>
                <td> <span v-if="item.days != null">{{ item.days }} يوم </span> </td>

                <td>
                  <span v-if="item.direct_manager == 'اعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span><br>
                  {{ item.direct_manager_reason }}
                </td>
                <td>
                  <span v-if="item.hr == 'اعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> 
                  {{ item.hr_reason }}
                </td>
                <td>
                  <span v-if="item.general_director == 'اعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> 
                  {{ item.general_director_reason }}
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
    </div>
    <br>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">طلبات الاستقاله</h5>
            </div>
          </div>
            <table class="datatables-basic table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>رقم المستند</th>
                  <th>تاريخ التقديم </th>
                  <th>الاسباب</th>
                  <td>المدير المباشر</td>
                  <th>الموارد البشريه</th>
                  <td>المدير العام</td>
                  <th class="action">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_resignation" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.time }}  </td>
                <td> {{ item.reasons }}  </td>
                <td> 
                  <span v-if="item.direct_manager == 'اعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span>                 
                  {{ item.direct_manager_reason }}
                </td>
                <td> 
                  <span v-if="item.hr == 'اعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'اعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>                  
                  {{ item.general_director_reason }}
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">العقــــــــوبات</h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>سبب العقوبه</th>
                <th>العقوبه</th>
                <th>الموارد البشريه</th>
                <td>المدير العام</td>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_punishment" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.reason_punishment }}  </td>
                <td> {{ item.hr_punishment }}  </td>
                <td> 
                  <span v-if="item.hr == 'اعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}     
                </td>
                <td> 
                  <span v-if="item.general_director == 'اعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span><br>
                  {{ item.reason_gd }}
                </td>
                <td> {{ item.note }}  </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->
    <br>
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">الحــــــــــوافز</h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>سبب الحــــــــــافز</th>
                <th>الحــــــــــافز</th>
                <th>الموارد البشريه</th>
                <td>المدير العام</td>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loop_incentive" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.reason_incentive }}  </td>
                <td> {{ item.hr_incentive }}  </td>
                <td> 
                  <span v-if="item.hr == 'اعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}     
                </td>
                <td> 
                  <span v-if="item.general_director == 'اعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span><br>
                  {{ item.reason_gd }}
                </td>
                <td> {{ item.note }}  </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],

      loop_training           : [],
      loop_message            : [],
      loop_covenant           : [],
      loop_overtime           : [],
      loop_insurance          : [],
      loop_interview          : [],
      loop_Interview          : [],
      loop_CarRequest         : [],
      loop_MoveToBranch       : [],
      loop_JobDescription     : [],
      loop_StaffEvaluation    : [],
      loop_EvaluationScores   : [],
      loop_MaintenanceRequest : [],

      loop_vacation           : [],
      loop_resignation        : [],
      loop_punishment         : [],
      loop_dismissal          : [],

      loop_incentive          : [],

      id      : this.$route.params.id,
      loopScores : [],


    }
  },

  mounted () {
      window.document.title = "ملف الموظف";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`${urlSite}/process.muazaf/${this.id}`).then(res => {
          this.loop_training            = res.data.training
          this.loop_covenant            = res.data.covenant
          this.loop_overtime            = res.data.overtime
          this.loop_insurance           = res.data.insurance
          this.loop_CarRequest          = res.data.CarRequest
          this.loop_MoveToBranch        = res.data.MoveToBranch
          this.loop_StaffEvaluation     = res.data.StaffEvaluation
          this.loop_MaintenanceRequest  = res.data.MaintenanceRequest

          this.loop_vacation            = res.data.vacation
          this.loop_resignation         = res.data.resignation
          this.loop_punishment          = res.data.punishment
          this.loop_dismissal           = res.data.dismissal
          this.loop_incentive           = res.data.incentive



          /*
          this.loop_message = data.res.message
          
          this.loop_interview = data.res.interview
          this.loop_Interview = data.res.Interview
          this.loop_JobDescription = data.res.JobDescription
          this.loop_EvaluationScores = data.res.EvaluationScores
          */

          this.loopEmployee = res.data.employee
          this.loopScores   = res.data.scores
        })
    
    },

 









 }
}
</script>
   