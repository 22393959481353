
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">انتقال موظف بين الفروع</h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>اسم الموظف </th>
                <th>التاريخ</th>
                <th>المهنه</th>
                <th>المهنه الجديده</th>
                <th>الاداره الحاليه</th>
                <th>الاداره الجديدة</th>
                <th>العنوان الحالى</th>
                <th>العنوان الجديد</th>

                <th>الموظف</th>
                <th>المدير المباشر</th>
                <th>الموارد البشريه</th>
                <th>المدير العام</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.old_occupation }}  </td>
                <td> {{ item.new_occupation }}  </td>
                <td> 
                  <span v-for="adminis in loopDirectManager" :key="adminis">
                    <span v-if="adminis.id == item.old_administration">{{ adminis.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="adminis in loopDirectManager" :key="adminis">
                    <span v-if="adminis.id == item.new_administration">{{ adminis.name }}</span>
                  </span>   
                </td>

                <td> {{ item.current_address }}  </td>
                <td> {{ item.new_address }}  </td>
        

                <td> 
                  <span v-if="item.muazaf_approve == 'تم الاعتماد'" class="badge bg-label-success">{{ item.muazaf_approve }} </span>
                  <span v-if="item.muazaf_approve == 'رفض'" class="badge bg-label-danger">{{ item.muazaf_approve }} </span>  <br>
                  {{ item.muazaf_reason }}             
                </td>
                <td> 
                  <span v-if="item.direct_manager == 'تم الاعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span>  <br>
                  {{ item.direct_manager_reason }}             
                </td>
                <td> 
                  <span v-if="item.hr == 'old_administration'" class="badge bg-label-success">>تم ارسال الطلب للادارة الحالية</span>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}             
                </td>

                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span><br>
                  {{ item.general_director_reason }}             
                </td>
                <td> {{ item.note }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, 
                                            item.author, 
                                            item.muazaf,
                                            item.date,
                                            item.old_occupation,
                                            item.new_occupation,
                                            item.old_administration,
                                            item.new_administration,
                                            item.current_address,
                                            item.new_address,
                                            item.direct_manager,
                                            item.direct_manager_reason,
                                            item.new_administration_manager,
                                            item.hr,
                                            item.hr_reason,
                                            item.muazaf_approve,
                                            item.muazaf_reason,
                                            item.employee_signature,
                                            item.time_last_update,
                                            item.general_director,
                                            item.acount_type
                      )"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">

                  
                  <div class="col-sm-6">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                    <select v-model="muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>

                <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">التاريخ</label>
                    <input type="date" v-model="date" class="form-control">
                </div>
                <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">المهنه</label>
                    <input type="text" v-model="old_occupation" class="form-control">
                </div>
                <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">المهنة الجديدة</label>
                    <input type="text" v-model="new_occupation" class="form-control">
                </div>
                <div class="col-sm-6">
                  <label class="col-form-label" for="basic-default-name">الادارة الحاليه</label>
                  <select v-model="old_administration" class="form-control">
                    <option v-for="item in loopDirectManager" :key="item" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>

                <div class="col-sm-6">
                  <label class="col-form-label" for="basic-default-name">الادارة الجديدة</label>
                  <select v-model="new_administration" class="form-control">
                    <option v-for="item in loopDirectManager" :key="item" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>

                <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">العنوان الحالي</label>
                    <input type="text" v-model="current_address" class="form-control">
                </div>
                <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">العنوان الجديد</label>
                    <input type="text" v-model="new_address" class="form-control">
                </div>

              </div>




            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="edit_id">

              <div class="row mb-3" v-if="edit_author == user_id">
                      <div class="col-sm-12">
                        <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                        <select v-model="edit_muazaf" class="form-control form-select">
                          <option v-for="item in loopEmployee" 
                                  :key="item" 
                                  :value="item.id">
                                  {{ item.name }}
                                </option>
                        </select>
                      </div>


                      <div class="col-sm-6">
                        <label class="col-form-label" for="basic-default-name">المهنه</label>
                        <input type="text" v-model="edit_old_occupation" class="form-control">
                      </div>
                      <div class="col-sm-6">
                        <label class="col-form-label" for="basic-default-name">المهنة الجديدة</label>
                        <input type="text" v-model="edit_new_occupation" class="form-control">
                      </div>
                      <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الادارة الحاليه</label>
                      <select v-model="edit_old_administration" class="form-control">
                        <option v-for="item in loopDirectManager" :key="item" :value="item.id">{{ item.name }}</option>
                      </select>
                      </div>

                      <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الادارة الجديدة</label>
                      <select v-model="edit_new_administration" class="form-control">
                        <option v-for="item in loopDirectManager" :key="item" :value="item.id">{{ item.name }}</option>
                      </select>
                      </div>

                      <div class="col-sm-6">
                        <label class="col-form-label" for="basic-default-name">العنوان الحالي</label>
                        <input type="text" v-model="edit_current_address" class="form-control">
                      </div>
                      <div class="col-sm-6">
                        <label class="col-form-label" for="basic-default-name">العنوان الجديد</label>
                        <input type="text" v-model="edit_new_address" class="form-control">
                      </div>
                  </div>


              <div v-if="employee_acount_type == 5  && edit_hr == 'old_administration'">
                <div v-if="acount_type == 'direct_manager'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد  مدير الادارة التابع لها </label>
                    <div class="col-sm-9">
                      <select v-model="edit_direct_manager" class="form-control form-select">
                        <option value=""></option>
                        <option value="تم الاعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_direct_manager_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>
              <div v-if="employee_acount_type < 5">
                <div v-if="acount_type == 'direct_manager'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد  مدير الادارة التابع لها </label>
                    <div class="col-sm-9">
                      <select v-model="edit_direct_manager" class="form-control form-select">
                        <option value=""></option>
                        <option value="تم الاعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_direct_manager_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>


              <div v-if="employee_acount_type == 5  && edit_direct_manager == 'تم الاعتماد'">
                <div v-if="acount_type == 'muazaf'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد الموظف</label>
                    <div class="col-sm-9">
                      <select v-model="edit_muazaf_approve" class="form-control form-select">
                        <option value=""></option>
                        <option value="تم الاعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_muazaf_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>

              <div v-if="employee_acount_type < 5">
                <div v-if="acount_type == 'muazaf'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد الموظف</label>
                    <div class="col-sm-9">
                      <select v-model="edit_muazaf_approve" class="form-control form-select">
                        <option value=""></option>
                        <option value="تم الاعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_muazaf_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>




              
                <div v-if="acount_type == 'hr'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد الموارد البشرية</label>
                    <div class="col-sm-9">
                      <select v-model="edit_hr" class="form-control form-select">
                        <option value=""></option>
                        <option value="old_administration">ارسال الطلب للادارة الحالية</option>
                        <optgroup v-if="employee_acount_type == 5  && edit_muazaf_approve == 'تم الاعتماد'">
                          <option value="تم الاعتماد">اعتماد</option>
                          <option value="رفض">رفض</option>
                        </optgroup>
                        <optgroup v-if="employee_acount_type < 5">
                          <option value="تم الاعتماد">اعتماد</option>
                          <option value="رفض">رفض</option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_hr_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              
              


              <div v-if="employee_acount_type == 5  && edit_hr == 'تم الاعتماد'">
                <div v-if="acount_type == 'general_director'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد المدير العام</label>
                    <div class="col-sm-9">
                      <select v-model="edit_general_director" class="form-control form-select">
                        <option value=""></option>
                        <option value="تم الاعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_general_director_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>
              <div v-if="employee_acount_type < 5">
                <div v-if="acount_type == 'general_director'">
                  <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد المدير العام</label>
                    <div class="col-sm-9">
                      <select v-model="edit_general_director" class="form-control form-select">
                        <option value=""></option>
                        <option value="تم الاعتماد">اعتماد</option>
                        <option value="رفض">رفض</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                      <div class="col-sm-9">
                        <textarea v-model="edit_general_director_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                      </div>
                  </div>
                </div>
              </div>




            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

    
  </div> <!-- container -->   
</template>



<style>
tr .text-nowrap {
    white-space: normal !important;
    max-width: 300px;
}
</style>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopUsers: [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      loopDirectManager: [],
      user_id : localStorage.getItem("user_id"),
      acount_type : localStorage.getItem('acount_type'),

      muazaf:                 "",
      date:                   "",
      old_occupation:         "",
      new_occupation:         "",
      old_administration: "",
      new_administration:     "",
      current_address:        "",
      new_address:            "",
      direct_manager:         "",
      new_administration_manager: "",

      


      edit_author:                "",
      edit_edit_id:                "",
      edit_muazaf:                 "",
      edit_date:                   "",
      edit_old_occupation:         "",
      edit_new_occupation:         "",
      edit_old_administration: "",
      edit_new_administration:     "",
      edit_current_address:        "",
      edit_new_address:            "",
      edit_direct_manager:         "",
      edit_new_administration_manager: "",


      edit_hr: "",
      edit_hr_reason: "",

      edit_muazaf_approve: "",
      edit_reason: "",

      edit_manager_reason: "",
      edit_direct_manager_reason: "",

      edit_direct_manager: "",

      edit_general_director: "",
      edit_general_director_reason: "",
    }
  },

  mounted () {
      window.document.title = "انتقال موظف بين الفروع";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    acceptRow(id, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'move_to_branch',
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/move.to.branch`)
        .then(res => {
          this.loopEmployee     = res.data.employee
          this.loopDirectManager   = res.data.direct_manager
          this.loopData         = res.data.data
          this.loopUsers = res.data.users

        })
    
    },




    store  () {
      axios.post(`${urlSite}/store.move.to.branch`, {
        author                 : localStorage.getItem("user_id"),
        muazaf              	 : this.muazaf,
        date	                 : this.date,
        old_occupation	       : this.old_occupation,
        new_occupation	       : this.new_occupation,
        old_administration     : this.old_administration,
        new_administration	   : this.new_administration,
        current_address        : this.current_address,
        new_address	           : this.new_address,
        direct_manager	      : this.direct_manager,
        new_administration_manager	: this.new_administration_manager,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    // Get Data For Edit
    openEditModel ( id,
                    author,
                    muazaf,
                    date, 
                    old_occupation, 
                    new_occupation, 
                    old_administration, 
                    new_administration, 
                    current_address,
                    new_address, 
                    direct_manager, 
                    direct_manager_reason, 
                    new_administration_manager, 
                    hr, 
                    hr_reason, 
                    muazaf_approve, 
                    muazaf_reason, 
                    employee_signature, 
                    general_director,
                    general_director_reason,
                    acount_type
  
    ) {

        this.edit_id                      = id  
        this.edit_author                  = author  
        this.edit_muazaf                  = muazaf  
        this.edit_date                    = date
        this.edit_old_occupation          = old_occupation
        this.edit_new_occupation          = new_occupation
        this.edit_old_administration      = old_administration
        this.edit_new_administration      = new_administration
        this.edit_current_address         = current_address
        this.edit_new_address             = new_address
        this.edit_direct_manager          = direct_manager
        this.edit_new_administration_manager = new_administration_manager

        this.edit_hr                      = hr
        this.edit_hr_reason               = hr_reason
        this.edit_direct_manager_reason   = direct_manager
        this.edit_direct_manager_reason   = direct_manager_reason

        this.edit_muazaf_approve          = muazaf_approve
        this.edit_muazaf_reason           = muazaf_reason

        this.edit_employee_signature      = employee_signature, 
        this.edit_general_director        = general_director,    
        this.edit_general_director_reason = general_director_reason
        this.employee_acount_type         = acount_type

    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.move.to.branch`, {
        id                     : this.edit_id, 
        muazaf              	 : this.edit_muazaf,
        date	                 : this.edit_date,
        old_occupation	       : this.edit_old_occupation,
        new_occupation	       : this.edit_new_occupation,
        old_administration : this.edit_old_administration,
        new_administration	   : this.edit_new_administration,
        current_address        : this.edit_current_address,
        new_address	           : this.edit_new_address,
        direct_manager	       : this.edit_direct_manager,
        new_administration_manager	: this.edit_new_administration_manager,
        acount_type            : localStorage.getItem('acount_type'),

        hr : this.edit_hr,
        hr_reason : this.edit_hr_reason,

        direct_manager  : this.edit_direct_manager,
        general_director        : this.edit_general_director,
        general_director_reason : this.edit_general_director_reason,

        direct_manager_reason : this.edit_direct_manager_reason,
        direct_manager_reason : this.edit_direct_manager_reason,
           
        muazaf_approve : this.edit_muazaf_approve,
        muazaf_reason  : this.edit_muazaf_reason,

        
        employee_signature      : this.edit_employee_signature,
        general_director        : this.edit_general_director,   
        general_director_reason : this.edit_general_director_reason,




      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "move_to_branch", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   




