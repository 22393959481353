
<template>
 
  <div class="container">
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">
          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">الملفات المرفقة</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>

        <table class="datatables-basic table table-bordered dataTable" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
          <thead class="table-light">
            <tr>
              <th>#</th>
              <th>توقيت رفع الملف</th>
              <th>توقيت الاشعار</th>
              <th>ملاحظــــــــات</th>
              <th>تنزيل الملف</th>
              <th>تحكـــم</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr v-for="(item, index) in loopFiles" :key="item">
              <td> {{ index + 1  }}  </td>
              <td> {{ item.time }}  </td>
              <td> {{ item.notifications_time }}  </td>
              <td> {{ item.note }}  </td>
              <td> 
                <a class="btn-action" :href="'https://furncn.com/back-end/public/file_time/'+item.file">
                    <i class="fas fa-arrow-alt-to-bottom"></i>
                </a>
              </td>
              <td>
                  <a  class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.notifications_time, item.note)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  
    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <div class="form-floating form-floating-outline mb-4">
                  <input type="file" @change="uploadPicture" accept=".doc,.docx,.pdf,.txt,.xlsx,.rar,.zip" class="form-control" placeholder="" />
                  <label for="html5-date-input">الملف المرفق</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="date" class="form-control" placeholder="" v-model="notifications_time"/>
                  <label for="html5-date-input">توقيت الاشعار</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <textarea class="form-control" placeholder="" cols="30" rows="10" v-model="note"></textarea>
                  <label for="html5-date-input">ملاحظــــــــات</label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->

    
  
    <!-- Edit Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانت </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input v-model="edit_id" type="hidden">

              <div class="form-floating form-floating-outline mb-4">
                  <input type="file" @change="uploadPicture" accept=".doc,.docx,.pdf,.txt,.xlsx,.rar,.zip" class="form-control" placeholder="" />
                  <label for="html5-date-input">الملف المرفق</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <input type="date" class="form-control" placeholder="" v-model="edit_notifications_time"/>
                  <label for="html5-date-input">توقيت الاشعار</label>
              </div>
              <div class="form-floating form-floating-outline mb-4">
                  <textarea class="form-control" placeholder="" cols="30" rows="10" v-model="edit_note"></textarea>
                  <label for="html5-date-input">ملاحظــــــــات</label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Edit Row -->

  </div> <!-- container -->   
</template>




<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",
 
  data() {
    return {
      // Validation
      loopFiles   : [],
      acount_type : localStorage.getItem('acount_type'),
      author      : localStorage.getItem("user_id"),

      note: "",
      notifications_time: "",

      edit_id: "",
      edit_note: "",
      edit_notifications_time: "",
      uploadFilePicture : {
        file: null,
        imageUrl: null,
        getPicture: null
      }, 
    }
  },

  mounted () {
      window.document.title = "الملفات المرفقة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    getData(){
      axios.get(`${urlSite}/file.time`)
        .then(res => {
          this.loopFiles    = res.data
        })
    
    },

    uploadPicture(e){
      this.uploadFilePicture.file = e.target.files[0];
      this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      this.uploadFilePicture.getPicture = e.target.files[0].name;
    },


    store  () {
      const formData = new FormData();
      formData.append("notifications_time"    , this.notifications_time);
      formData.append("note"                  , this.note);
      formData.append("file"                  , this.uploadFilePicture.file);
      axios.post(`${urlSite}/store.file.time`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },



    // Get Data For Edit
    openEditModel (id, notifications_time, note) {
        this.edit_id                      = id  
        this.edit_notifications_time      = notifications_time
        this.edit_note                    = note
    }, // openEditModel


    update () {
      const formData = new FormData();
      formData.append("id"                       , this.edit_id);
      formData.append("notifications_time"  , this.edit_notifications_time);
      formData.append("note"                , this.edit_note);
      formData.append("file"                     , this.uploadFilePicture.file);
      axios.post(`${urlSite}/update.file.time`, formData).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
          axios.post(`${urlSite}/destroy`, {
            id   : id, 
            table: "file_time", 
          }).then(res => {
            this.getData();
          });
          Swal.fire(
            'حــــذف !',
            'تم حذف البيانات بنجاح',
            'success'
          )
        }
      })
    }, // onDelete

 }
}
</script>
   