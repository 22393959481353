
<template>
  <!--
  https://www.pornhub.com/pornstar/violet-starr/videos
  https://www.pornhub.com/model/bananacoko
  https://www.pornhub.com/pornstars?page=4
  https://xn--mgbkt9eckr.net/threads/%D8%A3%D8%AE%D8%AA%D9%8A-%D8%A7%D9%84%D9%85%D8%B7%D9%84%D9%82%D8%A9-%D8%AA%D8%B3%D8%AA%D8%BA%D9%84-%D9%87%D9%8A%D8%AC%D8%A7%D9%86%D9%8A-%D8%A7%D9%84%D8%B3%D9%84%D8%B3%D9%84%D8%A9-%D8%A7%D9%84%D8%A3%D9%88%D9%84%D9%8A-%D9%80-%D8%B9%D8%B4%D8%B1%D8%A9-%D8%A3%D8%AC%D8%B2%D8%A7%D8%A1-10-6-2022.79139/
  https://www.tubev.pro/categories/1673/girls-kissing?hl=ar
-->
  <div class="container">

    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">تسليم العهدة</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <table class="datatables-basic table table-bordered dataTable" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info" style="width: 1396px;">
              <thead>
                <tr>
                  <th>#</th>
                  <th>رقم المستند</th>
                  <th>الموظف المسؤل</th>
                  <th>الموظف المستلم</th>
                  <th>الفرع</th>
                  <th>البيان</th>
                  <th>التوقيت</th>
                  <th>الموارد البشرية</th>
                  <th>المحاسب</th>
                  <th>المدير العام</th>
                  <th>ملاحظات</th>
                  <th>المرفقات</th>
                  <th style="width: 150px;">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.responsible_employee">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.branch }}  </td>
                <td> {{ item.statement }}  </td>
                <td> {{ item.time }}  </td>
                <td> 
                  <select v-if="acount_type == 'hr'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'hr')">
                    <option value=""></option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>  
                </td>
                <td> 
                  <div v-if="item.acount_type == 5 && item.hr == 'تم الاعتماد'">
                    <select  v-if="user_id == '3'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'accountant')">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div v-if="item.acount_type < 5">
                    <select  v-if="user_id == '3'" class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'accountant')">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <span v-if="item.accountant == 'تم الاعتماد'" class="badge bg-label-success">{{ item.accountant }} </span>
                  <span v-if="item.accountant == 'رفض'" class="badge bg-label-danger">{{ item.accountant }} </span>   
                </td>
                <td> 
                  <div v-if="item.acount_type == 5 && item.accountant == 'تم الاعتماد'">
                    <select v-if="acount_type == 'general_director'"  class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'general_director')">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <div v-if="item.acount_type < 5">
                    <select v-if="acount_type == 'general_director'"  class="form-control form-select" @change="acceptRow(item.id, item.muazaf, 'general_director')">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>
                </td>
                <td> {{ item.note }}  </td>
                <td> 
                  <router-link :to="'/files/covenant/' + item.id">
                    المرفقات 
                  </router-link>
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a class="btn-action"
                     data-toggle="modal" 
                     data-target="#uploadModal" 
                     @click="openUploadModel(item.id)">
                     <i class="fas fa-arrow-alt-up"></i>
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.responsible_employee, item.muazaf, item.branch, item.statement, item.note, item.acount_type, item.author)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
                </div>
              </div>

    

    <!-- Upload File -->
    <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="uploadFile()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <div class="uploadFileBox">
                <label for="upload-file">
                  <i class="fal fa-cloud-upload"></i>
                  <strong>رفــــــــع ملـــف</strong>
                  <strong>PDF, ZIP, RAR, PNG, JPG, PNG, DOCX, XLSX, CSV</strong>
                  <input  type="file"  
                          class="form-control" 
                          id="upload-file" 
                          required
                          @change="uploadPicture" 
                          accept="pdf, zip, rar, png, jpg, gpeg, png, docx, xlsx, csv"
                  />
                </label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Upload File -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف المسؤل </label>
                  <div class="col-sm-9">
                    <select v-model="responsible" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف المستلم </label>
                  <div class="col-sm-9">
                    <select v-model="muazaf" class="form-control form-select">
                      <option 
                              v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">  الفرع  </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="branch" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> بيان بالعهده المسلمه </label>
                  <div class="col-sm-9">
                    <textarea v-model="statement" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

              <li v-for="error in formValdatorCreatePlanss" :key="error"> {{ error  }} </li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body" v-if="author == user_id">

              <input type="hidden"  v-model="editID" >

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف المسؤل </label>
                  <div class="col-sm-9">
                    <select v-model="edit_responsible" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظق المستلم </label>
                  <div class="col-sm-9">
                    <select v-model="edit_muazaf" class="form-control form-select">
                      <option 
                              v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">  الفرع  </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="edit_branch" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> بيان بالعهده المسلمه </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_statement" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}
</style>



<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopUsers: [],
      loopData    : [],
      loopEmployee: [],
      loopScores  : [],
      loopFiles   : [],
      acount_type : localStorage.getItem('acount_type'),
      user_id     : localStorage.getItem("user_id"),

      responsible : "",
      muazaf      : "",
      branch      : "",
      statement   : "",
      note        : "",


      edit_responsible  : "",
      edit_muazaf       : "",
      edit_branch       : "",
      edit_statement    : "",
      edit_note         : "",
      employee_acount_type: "",

      author: "",
      
      // Upload Files
      row_id: null,
      uploadFilePicture : {
        picture: null,
        imageUrl: null,
        getPicture: null
      },
      
    }
  },

  mounted () {
      window.document.title = "تسليم العهدة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    acceptRow(id, muazaf, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'covenant',
        muazaf: muazaf,
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/covenant`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopScores   = res.data.scores
          this.loopData     = res.data.data
          this.loopFiles    = res.data.files
          this.loopUsers = res.data.users
        })
    },


    openUploadModel(id) {
      this.row_id = id
    },

    uploadPicture(e){
      this.uploadFilePicture.picture = e.target.files[0];
      this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      this.uploadFilePicture.getPicture = e.target.files[0].name;
    },

    
    uploadFile  () {
      const formData = new FormData();
      formData.append("table",        "covenant");
      formData.append("row",          this.row_id);
      formData.append("author",       localStorage.getItem("user_id"));
      formData.append("acount_type",  localStorage.getItem("acount_type"));
      formData.append("file",         this.uploadFilePicture.picture); 
      //for (const i of Object.keys(this.uploadFilePicture.picture)) {
      //      formData.append('files', this.uploadFilePicture.picture[i])
      //      alert("file ma,e" + this.uploadFilePicture.picture[i])
      //    }
      axios.post(`${urlSite}/upload.files`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    store  () {
      axios.post(`${urlSite}/store.covenant`, {
          author        : localStorage.getItem("user_id"),
          responsible: this.responsible,
          muazaf  : this.muazaf,
          branch     : this.branch,
          statement  : this.statement,
          note       : this.note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },




    // Get Data For Edit
    openEditModel (getID, responsible, muazaf, branch, statement, note, acount_type, author) {
      this.editID      = getID
      this.edit_responsible    = responsible
      this.edit_muazaf   = muazaf
      this.edit_branch     = branch
      this.edit_statement = statement
      this.edit_note  = note
      this.employee_acount_type = acount_type
      this.author = author
    }, // openEditModel




    update () {
      axios.post(`${urlSite}/update.covenant`, {
        id:this.editID, 
        responsible:this.edit_responsible, 
        muazaf:this.edit_muazaf,
        branch   :this.edit_branch,
        statement:this.edit_statement,
        note     :this.edit_note
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "covenant", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   