
<template>
 
  <div class="container">
   ff {{ user }}
  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.card .action {
  width: 115px;
}
</style>



<script>
import { ref } from 'vue';

const product = ref(null);

import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],

      getDataFromMuazaf: "",

      user	: "",

      
    }
  },

  mounted () {
      window.document.title = "طلب الاجازة";
      this.getData();
      const llll = localStorage.getItem("token")
      console.log(llll);
  }, // mounted

  methods : {


    async getData(){

      axios.get(`${urlSite}/details`, {
        //headers: {
        //    'Authorization': "Bearer " + localStorage.getItem("token"),
        //    "x-access-token": localStorage.getItem("token"),
        //  },
      })
      
        .then(res => {
          this.user = res.data
          console.log(res);
        })
    
    },








 }
}
</script>
   