import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
//const router = useRouter()

import store from "./store";

import Vue from 'vue'

import axios from 'axios'
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
axios.defaults.headers.common = {
    'Authorization': "Bearer " + localStorage.getItem("token"),
    "x-access-token": localStorage.getItem("token"),
};

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)
const ss = app.config.globalProperties.$hostname = 'http://localhost:8080'
console.log(app.config.globalProperties.$hostname)

app.use(VueSweetalert2);

createApp(App).use(store).use(router).mount("#app");
