
<template>
 
  <div class="container">
    <br><br><br>

    <div class="dashbord" v-if="acount_type == 'general_director' || acount_type == 'hr'">
      <h3>لوحــــة الاحصائيات</h3>
      <br>
      <div class="row">

          <div class="col-md-2">
            <router-link to="/member" class="box">
              <strong v-html="muazaf"></strong>
              <span>الموظفيــــــن</span>
            </router-link>
          </div><!-- row -->

          <div class="col-md-2">
            <router-link to="/staff.evaluation" class="box">
              <strong v-html="staff"></strong>
              <span>التقيمـــات</span>
            </router-link>
          </div><!-- row -->

          <div class="col-md-2">
            <router-link to="/covenant" class="box">
              <strong v-html="covenant"></strong>
              <span>تسليم عهدة</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/maintenance.request" class="box">
              <strong v-html="maintenance"></strong>
              <span>طلبات الصيانة</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/car.request" class="box">
              <strong v-html="car_request"></strong>
              <span>طلب سيارة</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/insurance" class="box">
              <strong v-html="insurance"></strong>
              <span>طلبات التامين</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/overtime" class="box">
              <strong v-html="overtime"></strong>
              <span>وقت اضافي</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/interview" class="box">
              <strong v-html="interview"></strong>
              <span>مقابلة شخصية</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/job.description" class="box">
              <strong v-html="description"></strong>
              <span>وصف وظيفة</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/move.to.branch" class="box">
              <strong v-html="move_branch"></strong>
              <span>انتقال لفرع</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/training" class="box">
              <strong v-html="training"></strong>
              <span>تدريب</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/punishment" class="box">
              <strong v-html="punishment"></strong>
              <span>طلبات العقوبة</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/incentive" class="box">
              <strong v-html="incentive"></strong>
              <span>طلبات حافز</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/vacation" class="box">
              <strong v-html="vacation"></strong>
              <span>طلبات الاجازة</span>
            </router-link>
          </div><!-- row -->

          <div class="col-md-2">
            <router-link to="/resignation" class="box">
              <strong v-html="resignation"></strong>
              <span>طابات استقالة</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/dismissal" class="box">
              <strong v-html="dismissal"></strong>
              <span>طلبات الاقالة</span>
            </router-link>
          </div><!-- row -->


          <div class="col-md-2">
            <router-link to="/work.assignment" class="box">
              <strong v-html="work_assignment"></strong>
              <span>طلبات الانتداب</span>
            </router-link>
          </div><!-- row -->

          <div class="col-md-2">
            <router-link to="/absence" class="box">
              <strong>0</strong>
              <span>الحضـــور والغياب</span>
            </router-link>
          </div><!-- row -->

          <div class="col-md-2">
            <router-link to="/absence.filter" class="box">
              <strong>0</strong>
              <span>فلتر الحضـــور والغياب</span>
            </router-link>
          </div><!-- row -->



        </div>
    </div><!-- dashbord -->   


  </div> <!-- container -->   
</template>





<style scoped>
.dashbord .box {
    padding: 20px;
    box-shadow: 0px 0px 11px 0px #b6b6b699;
    background: #fff;
    border: 1px solid #544f5a85;
    border-radius: 2px;
    margin-bottom: 15px;
}

.dashbord .box strong, .dashbord .box span {
  display: block;
}

.dashbord .box strong {
    font-size: 40px;
}
a {
  display: block;
}
</style>








<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation

      acount_type : localStorage.getItem('acount_type'),

      muazaf      : null,
      staff       : null,
      covenant    : null,
      maintenance : null,
      car_request : null,
      insurance   : null,
      overtime    : null,
      interview   : null,
      description : null,
      move_branch : null,
      training    : null,
      punishment  : null,
      incentive   : null,
      vacation    : null,
      resignation : null,
      dismissal   : null,
      work_assignment : null,


      
    }
  },

  mounted () {
      window.document.title = "لوحة الاحصائيات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    getData(){
      axios.get(`${urlSite}/dashbord`)
        .then(res => {
          this.muazaf       = res.data.muazaf
          this.staff        = res.data.staff
          this.covenant     = res.data.covenant
          this.maintenance  = res.data.maintenance
          this.car_request  = res.data.car_request
          this.insurance    = res.data.insurance
          this.overtime     = res.data.overtime
          this.interview    = res.data.interview
          this.description  = res.data.description
          this.move_branch  = res.data.move_branch
          this.training     = res.data.training
          this.punishment   = res.data.punishment
          this.incentive    = res.data.incentive
          this.vacation     = res.data.vacation
          this.resignation  = res.data.resignation
          this.dismissal    = res.data.dismissal
          this.work_assignment = res.data.work_assignment
        })
    
    },











 }
}
</script>
   