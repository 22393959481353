<script>
  
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
export default {

  name: "MenuRight",
  data() {
      return {
        user_id     : localStorage.getItem("user_id"),
        status_auth : localStorage.getItem('status_auth'),
        acount_type : localStorage.getItem('acount_type'),
        notifications: [],
        startInterval: 0,
        count_notification: null,
        sp : 0,
      }
    },  
  mounted () {

    if (localStorage.getItem("status_auth")) {
      this.getCount();
      setInterval(() => {
        return this.getCount();
      }, 10000);
    } // status_auth
  
    
  }, // mounted

  methods : {

    getCount(){
      axios.get(`${urlSite}/notifications.count`)
        .then(res => {
            this.count_notification  = res.data.count
       })
    },

    logout () {
      localStorage.removeItem("user_id");
      localStorage.removeItem("status_auth");
      localStorage.removeItem("token");
      localStorage.removeItem("acount_type");
      localStorage.removeItem("reload");
      window.location.href = '/';
    },


  }

  // filter Payment
  // Dashbord



}
</script>


<template>
<div class="navMenu" v-if="$route.name != 'home'" style="display: block;">
  <img class="icon-menu"  src="https://icons.veryicon.com/png/o/miscellaneous/linear-icon-45/hamburger-menu-4.png" alt="">
  <a  @click="logout()">
    <i class="fal fa-sign-out-alt"></i>
  </a>
</div>

<aside id="layout-menu" v-if="$route.name != 'home' && $route.name != 'login' && $route.name != 'signup'" style="display: block;" class="layout-menu menu-right menu-vertical menu bg-menu-theme">
          <div class="app-brand demo">
            <a href="index.html" class="app-brand-link">
              <span class="app-brand-logo demo me-1">
                <span style="color: var(--bs-primary)">
                
                </span>
              </span>
              <span class="app-brand-text demo menu-text fw-semibold ms-2">Materio</span>
            </a>

            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
              <i class="mdi menu-toggle-icon d-xl-block align-middle mdi-20px"></i>
            </a>
          </div>

          <div class="menu-inner-shadow"></div>

          <ul class="menu-inner py-1">

          <!-- Dashboard -->
            <li class="menu-item" v-if="acount_type == 'general_director'">
              <router-link to="/dashbord" class="menu-link">
                <i class="far fa-chart-line"></i>
                <div> لوحة الاحصائيات  </div>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link to="/profile" class="menu-link">
                <i class="far fa-user-cog"></i>
                <div> الملف الشخصي </div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/notifications" class="menu-link">
                <i class="far fa-comments-alt"></i>
                <div> الاشعارات</div>
                <div class="badge bg-danger rounded-pill ms-auto">{{count_notification}}</div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr'">
              <router-link to="/member" class="menu-link">
                <i class="far fa-users"></i>
                <div> ملف الموظفين </div>
              </router-link>
            </li>  
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/absence" class="menu-link">
                <i class="far fa-user-plus"></i>
                <div> الحضور والغياب </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr'">
              <router-link to="/absence.filter" class="menu-link">
                <i class="far fa-user-plus"></i>
                <div>فلتر الحضور والغياب </div>
              </router-link>
            </li> 
            
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/staff.evaluation" class="menu-link">
                <i class="far fa-stars"></i>
                <div> تقييم الموظفين  </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/covenant" class="menu-link">
                <i class="far fa-file-archive"></i>
                <div  > تسليم العهدة </div>
              </router-link>
            </li> 
            
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager' || acount_type == 'muazaf'">
              <router-link to="/maintenance.request" class="menu-link">
                <i class="far fa-tools"></i>
                <div  > طلبات الصيانة </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager' || acount_type == 'muazaf'">
              <router-link to="/car.request" class="menu-link">
                <i class="far fa-car"></i>
                <div  > طلب سيــــارة </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'hr'">
              <router-link to="/insurance" class="menu-link">
                <i class="far fa-stethoscope"></i>
                <div  >التامين الطبي</div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="/message" class="menu-link">
                <i class="far fa-comments"></i>
                <div  >التواصل</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/overtime" class="menu-link">
                <i class="far fa-business-time"></i>
                <div  >  وقت  اضافى </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/job.description" class="menu-link">
                <i class="far fa-compact-disc"></i>
                <div  >وصف الوظيفة</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/interview" class="menu-link">
                <i class="far fa-user-check"></i>
                <div  >مقابله شخصيه </div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/training" class="menu-link">
                <i class="far fa-user-chart"></i>
                <div> تدريب موظف </div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="/move.to.branch" class="menu-link">
                <i class="far fa-code-branch"></i>
                <div>انتقال موظف بين الفروع</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/punishment" class="menu-link">
                <i class="far fa-user-times"></i>
                <div>العقـــــوبات</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/incentive" class="menu-link">
                <i class="fal fa-clipboard-user"></i>
                <div>الحــــــــــوافز</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'|| acount_type == 'muazaf'">
              <router-link to="/vacation" class="menu-link">
                <i class="far fa-weight-hanging"></i>
                <div>الاجازة</div>
              </router-link>
            </li> 
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'|| acount_type == 'muazaf'">
              <router-link to="/resignation" class="menu-link">
                <i class="fal fa-window-restore"></i>
                <div>طلبات الاستقاله</div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr' || acount_type == 'direct_manager'">
              <router-link to="/dismissal" class="menu-link">
                <i class="fal fa-user-alt-slash"></i>
                <div>اقاله موظف</div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/work.assignment" class="menu-link">
                <i class="fal fa-file-invoice"></i>
                <div>الانتـــداب</div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'general_director' || acount_type == 'hr'">
              <router-link to="/file.time" class="menu-link">
                <i class="far fa-file-pdf"></i>
                <div>ملفات بتوقيت</div>
              </router-link>
            </li>
            <li class="menu-item" @click="logout()">
              <a href="#" class="menu-link">
                <i class="fal fa-sign-out-alt"></i>
                <div  >تسجيل الخروج</div>
              </a>
            </li>

          </ul>
        </aside>
        <!-- / Menu -->

</template>

<style>
.menu-right i {
    font-weight: 400;
    margin-left: 15px;
    font-size: 21px;
    width: 30px;
    text-align: center;
}

#layout-menu {
  overflow: scroll;
}
</style>
