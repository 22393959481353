
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">  اضافة الحضور والغياب   </h5>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th>الهاتف</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }}  </td>
                <td> {{ item.phone }}  </td>
                <td class="hr-table-icon">
                    <a  :data-id="item.id" 
                        @click="absence(item.id, 'plus')"
                        class="btn-action btn-plus"> 
                        <i class="fas fa-plus"></i> 
                    </a>
                    <a  :data-id="item.id"
                        @click="absence(item.id, 'mins')"
                        class="btn-action btn-minus btn-mins"> 
                        <i class="fas fa-minus"></i> 
                    </a>
                    <a  :data-id="item.id" 
                        @click="absence(item.id, 'go')"
                        class="btn-action btn-departure">
                        <i class="fas fa-power-off"></i>
                    </a>
                    <a  :data-id="item.id"
                        @click="getUserID(item.id)"
                        class="btn-action overtime-time" 
                        data-toggle="modal" 
                        data-target="#overtime-time">
                        <i class="far fa-alarm-clock"></i>
                    </a>
                    <a  data-id="63" 
                        @click="getUserID(item.id)"
                        class="btn-action" 
                        data-toggle="modal" 
                        data-target="#deduction-salary">
                        <i class="far fa-money-check-edit-alt"></i>
                    </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->







    <!-- Create Row -->
    <div class="modal fade" id="overtime-time" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="absence(user_id, 'overtime')" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الوقت الاضافي </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="overtime" placeholder="عدد الساعات">
                  </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->




    <!-- Create Row -->
    <div class="modal fade" id="deduction-salary" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="absence(user_id, 'deduction')" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الخصم </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="deduction" placeholder="المبلغ">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> سبب الخصم </label>
                  <div class="col-sm-10">
                    <textarea class="form-control" cols="3" rows="3" v-model="message"></textarea>
                  </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->




    <!-- Create Row -->
  </div> <!-- container -->   
</template>

 
<script>
import axios from 'axios'
import { useRoute } from 'vue-router'

if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}

const route = useRoute();
 export default {
  name: "Member",

  data() {
    return {
      loopData: [],
      loopCity: [],
      user_id: null,
      overtime: null,
      deduction: null,
      message: null,

      acount: this.$route.params.type

    }
  },
 
  mounted () {
      window.document.title = "اضافة الحضور والغياب";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted




  methods : {
    getData(){
      axios.get(`${urlSite}/absence`)
        .then(res => {
          this.loopData = res.data.data
        })
                
    },


    absence(id, action) {
      axios.post(`${urlSite}/store.absence`, {
        user: id,
        action: action,
        overtime: this.overtime,
        deduction: this.deduction,
        message: this.message
      }).then(res => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: ' تحديث البيانات ',
            text: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },

    getUserID(id) {
      this.user_id = id
    }



 }
}
</script>
   