
<template>
  <div class="container">
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">طلب الاجازة</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div><div class="row"><div class="col-sm-12 col-md-6"><div class="dataTables_length" id="DataTables_Table_0_length"><label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="form-select"><option value="7">7</option><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="75">75</option><option value="100">100</option></select> entries</label></div></div><div class="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end"><div id="DataTables_Table_0_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control" placeholder="" aria-controls="DataTables_Table_0"></label></div></div></div><table class="datatables-basic table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info" style="width: 1396px;">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>رقم المستند</th>
                  <th>الموظف</th>
                  <th>تاريخ التقديم </th>
                  <th>بيانات الاجازه</th>
                  <th>نوع الاجازه</th>
                  <th>مدة الاجازه</th>
                  <th>البداية</th>
                  <th>النهاية</th>
                  <th>وسيلة السفر</th>
                  <th>الهاتف</th>
                  <th>العنوان</th>
                  <th>الموارد البشرية</th>
                  <th>المدير العام</th>
                  <th class="action">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <router-link to="#" v-if="employee.id == item.muazaf">{{ employee.name }}</router-link>
                  </span>   
                  
                </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.vacation_data }}  </td>
                <td> {{ item.vacation_type }}  </td>
                <td> {{ item.count_day }}  </td>
                <td> {{ item.start }}  </td>
                <td> {{ item.end }}  </td>
                <td> {{ item.travel }}  </td>
                <td> {{ item.phone_muazaf }}  </td>
                <td> {{ item.address_muazaf }}  </td>
                <td>
                  <span v-if="item.direct_manager == 'تم الاعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span> 
                  {{ item.direct_manager_reason }}
                </td>
                <td>
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> 
                  {{ item.hr_reason }}
                </td>
                <td>
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> 
                  {{ item.general_director_reason }}
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.muazaf,	item.date,	item.vacation_data,	item.vacation_type,	item.count_day,	item.start,	item.end,	item.travel,	item.address_muazaf,	item.phone_muazaf, item.direct_manager, item.direct_manager_reason, item.substitute_employee, item.hr, item.hr_reason, item.days, item.general_director, item.general_director_reason)"> 
                      
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
                </div>
              </div>


    




    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              					

              <div class="row">
                
                <div class="form-check col-md-4">
                  <input class="form-check-input" type="checkbox" id="userManagementRead">
                  <label class="form-check-label" for="userManagementRead"> صفحة الموظفين </label>
                </div>                
                <div class="form-check col-md-4">
                  <input class="form-check-input" type="checkbox" id="userManagementRead">
                  <label class="form-check-label" for="userManagementRead"> Read </label>
                </div>                
                <div class="form-check  col-md-4">
                  <input class="form-check-input" type="checkbox" id="userManagementRead">
                  <label class="form-check-label" for="userManagementRead"> Read </label>
                </div>
                <div class="form-check col-md-4">
                  <input class="form-check-input" type="checkbox" id="userManagementRead">
                  <label class="form-check-label" for="userManagementRead"> Read </label>
                </div>                
                <div class="form-check col-md-4">
                  <input class="form-check-input" type="checkbox" id="userManagementRead">
                  <label class="form-check-label" for="userManagementRead"> Read </label>
                </div>                
                <div class="form-check  col-md-4">
                  <input class="form-check-input" type="checkbox" id="userManagementRead">
                  <label class="form-check-label" for="userManagementRead"> Read </label>
                </div>


              </div>
 






            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="edit_id">

              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" v-model="edit_date" type="date" id="html5-date-input">
                  <label for="html5-date-input">تاريخ التقديم</label>
              </div>
              					

              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" id="exampleFormControlSelect1" v-model="edit_vacation_data">
                    <option selected="">بيانات الاجازه</option>
                    <option value="داخليه">داخليه</option>
                    <option value="خارجيه">خارجيه</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" id="exampleFormControlSelect1" v-model="edit_vacation_type">
                    <option selected="">نوع الاجازه المطلوبه </option>
                    <option value="اجازه اعتياديه">اجازه اعتياديه</option>
                    <option value="اجازه بدون راتب">اجازه بدون راتب</option>
                    <option value="اجازه مرضيه">اجازه مرضيه</option>
                  </select>
                </div>
              </div>
              	
              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" id="exampleFormControlSelect1" v-model="edit_travel">
                    <option>وسيلة السفر</option>
                    <option value="جوا">جوا</option>
                    <option value="برا">برا</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline col-md-6">
                    <input type="text" class="form-control" id="floatingInput" placeholder="المـــــدة" v-model="edit_count_day">
                    <label for="floatingInput">مدة الاجازه</label>
                </div> <br>
              </div>
              				
              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="date" id="html5-date-input" v-model="edit_start">
                    <label for="html5-date-input">بداية الاجازة</label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="date" id="html5-date-input" v-model="edit_end">
                    <label for="html5-date-input">نهاية الاجازة</label>
                </div>
              </div>

              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="text" id="html5-date-input" v-model="edit_phone_muazaf" placeholder="الهاتف في البلد الاصلي">
                    <label for="html5-date-input">رقم الهاتف</label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="text" id="html5-date-input" v-model="edit_address_muazaf" placeholder="العنوان الاصلي">
                    <label for="html5-date-input"> العنوان </label>
                </div>
              </div>



              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" v-model="edit_direct_manager">
                    <option value="">حالة الطلب</option>
                    <option value="تم الاعتماد">تم الاعتماد</option>
                    <option value="رفض">رفض</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select v-model="edit_substitute_employee" aria-placeholder="" class="form-control form-select">
                    <option>الموظف البديل</option>
                    <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <textarea class="form-control" cols="3" rows="3" v-model="direct_manager_reason	" placeholder=""></textarea>
                    <label for="html5-date-input">سبب الرفض ان وجد</label>
                </div>
              </div>

              <div v-html="this.getDataFromMuazaf" class=""></div>

              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" v-model="edit_hr">
                      <option value="">حالة الطلب</option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                    <input class="form-control" type="number" id="html5-date-input" v-model="edit_days" placeholder="">
                    <label for="html5-date-input"> عدد ايام الاجازة </label>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <textarea class="form-control" cols="3" rows="3" v-model="edit_hr_reason" placeholder=""></textarea>
                    <label for="html5-date-input">سبب الرفض ان وجد</label>
                </div>
              </div>

              <div class="row">
                <div class="form-floating form-floating-outline mb-4 col-md-6">
                  <select class="form-select" v-model="edit_general_director">
                      <option value="">حالة الطلب</option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                  </select>
                </div>
                <div class="form-floating form-floating-outline mb-4 col-md-12">
                    <textarea class="form-control" cols="3" rows="3" v-model="edit_general_director_reason" placeholder=""></textarea>
                    <label for="html5-date-input">سبب الرفض ان وجد</label>
                </div>
              </div>


              



            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.card .action {
  width: 115px;
}
</style>



<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],

      getDataFromMuazaf: "",

      muazaf	: "",
      date	: "",
      vacation_data	: "",
      vacation_type	: "",
      count_day	: "",
      start	: "",
      end	: "",
      travel	: "",
      address_muazaf	: "",
      phone_muazaf	: "",

      edit_id : "",
      edit_muazaf	: "",
      edit_date	: "",
      edit_vacation_data	: "",
      edit_vacation_type	: "",
      edit_count_day	: "",
      edit_start	: "",
      edit_end	: "",
      edit_travel	: "",
      edit_address_muazaf	: "",
      edit_phone_muazaf	: "",

      edit_direct_manager	: "",
      edit_direct_manager_reason	: "",
      edit_substitute_employee	: "",
      edit_hr	: "",
      edit_hr_reason	: "",
      edit_days	: "",
      edit_general_director	: "",
      edit_general_director_reason		: "",

      
    }
  },

  mounted () {
      window.document.title = "طلب الاجازة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    acceptRow(id, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'vacation',
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/vacation`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopScores = res.data.scores
          this.loopData = res.data.data
          this.loopUsers = res.data.users
        })
    
    },

    store  () {
      axios.post(`${urlSite}/store.vacation`, {
        muazaf	        : localStorage.getItem('user_id'),
        date	          : this.date,
        vacation_data	  : this.vacation_data,
        vacation_type	  : this.vacation_type,
        count_day      	: this.count_day,
        start         	: this.start,
        end	            : this.end,
        travel	        : this.travel,
        address_muazaf	: this.address_muazaf,
        phone_muazaf	  : this.phone_muazaf,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },




    // Get Data For Edit
    openEditModel (id, muazaf,	date,	vacation_data, vacation_type,	count_day,	start,	end,	travel,	address_muazaf,	phone_muazaf, direct_manager, direct_manager_reason, substitute_employee, hr, hr_reason, days, general_director, general_director_reason) {
      this.edit_id             = id
      //this.edit_muazaf	       = muazaf
      this.edit_date	         = date
      this.edit_vacation_data	 = vacation_data
      this.edit_vacation_type	 = vacation_type
      this.edit_count_day      = count_day
      this.edit_start	         = start
      this.edit_end	           = end
      this.edit_travel	       = travel
      this.edit_address_muazaf = address_muazaf	
      this.edit_phone_muazaf	 = phone_muazaf

      this.edit_direct_manager	      = direct_manager
      this.edit_direct_manager_reason	= direct_manager_reason
      this.edit_substitute_employee	  = substitute_employee
      this.edit_hr                    = hr
      this.edit_hr_reason	            = hr_reason
      this.edit_days	                = days
      this.edit_general_director	    = general_director
      this.edit_general_director_reason	= general_director_reason


      axios.get(`${urlSite}/vacation.get.muazaf.data/${muazaf}`)
        .then(res => {
          this.getDataFromMuazaf = res.data
        })


    }, // openEditModel


    update () {
      alert(this.edit_direct_manager)
      axios.post(`${urlSite}/update.vacation`, {
        id	            : this.edit_id,
        muazaf	        : this.edit_muazaf,
        date	          : this.edit_date,
        vacation_data	  : this.edit_vacation_data,
        vacation_type	  : this.edit_vacation_type,
        count_day      	: this.edit_count_day,
        start         	: this.edit_start,
        end	            : this.edit_end,
        travel	        : this.edit_travel,
        address_muazaf	: this.edit_address_muazaf,
        phone_muazaf	  : this.edit_phone_muazaf,

        direct_manager  : this.edit_direct_manager,
        direct_manager_reason : this.edit_direct_manager_reason,
        substitute_employee : this.edit_substitute_employee,
        hr    : this.edit_hr,
        hr_reason : this.edit_hr_reason,
        days  : this.edit_days,
        general_director  : this.edit_general_director,
        general_director_reason : this.edit_general_director_reason,


      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "vacation", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   