
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">تدريب موظف</h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>مقدم الطلب</th>
                <th>الموظف</th>
                <th>التاريخ</th>
                <th>المهنه</th>
                <th>القسم الادارى</th>
                <th>التقييم الحالى للموظف</th>
                <th>التقييم بعد التدريب</th>
                <th>الاحتياج التدريبى له</th>
                <th>مدة التدريب</th>
                <th>مســــؤل التدريب</th>
                <th>الموارد البشريه</th>
                <td>المدير العام</td>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.author">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.occupation }}  </td>
                <td> {{ item.administrative_section }}  </td>
                <td> {{ item.evaluation_now }}  </td>
                <td> {{ item.evaluation_training }}  </td>
                <td> {{ item.training_need }}  </td>
                <td> {{ item.training_time }}  </td>
                <td> {{ item.training_officer }}  </td>
                <td> 
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.refuse_hr }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  {{ item.refuse_gd }}
                </td>
                <td> {{ item.note }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.author, item.muazaf,	item.date,	item.occupation,	item.administrative_section,	item.training_need,	item.training_time,	item.training_officer, item.evaluation_now,	item.evaluation_training, item.hr, item.refuse_hr, item.general_director, item.refuse_gd, item.acount_type)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
              </div>


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> التاريخ  </label>
                  <div class="col-sm-9">
                    <input type="date" v-model="date" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المهنة</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="occupation" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">القسم الادارى</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="administrative_section" class="form-control">
                  </div>
              </div>



              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">التقييم الحالى للموظف </label>
                  <div class="col-sm-9">
                    <select class="form-control form-select" v-model="evaluation_now">
                        <option value="جيد">جيد</option>
                        <option value="جيد جدا">جيد جدا</option>
                        <option value="ممتاز">ممتاز</option>
                        <option value="مقبول">مقبول</option>
                        <option value="غير مناسب">غير مناسب</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-3 col-form-label" for="basic-default-name">التقييم بعد التدريب </label>
                  <div class="col-sm-9">
                    <select class="form-control form-select" v-model="evaluation_training">
                        <option value="جيد">جيد</option>
                        <option value="جيد جدا">جيد جدا</option>
                        <option value="ممتاز">ممتاز</option>
                        <option value="مقبول">مقبول</option>
                        <option value="غير مناسب">غير مناسب</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الاحتياج التدريبى له </label>
                  <div class="col-sm-9">
                    <textarea  v-model="training_need" cols="2" rows="2" class="form-control"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> مدة التدريب </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="training_time" class="form-control">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> مســــؤل التدريب </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="training_officer" class="form-control">
                  </div>
              </div>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="edit_id" />

              <div v-if="user_id == author">

                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> الموظف  </label>
                  <div class="col-sm-9">
                    <select v-model="edit_muazaf" class="form-control form-select">
                      <option v-for="item in loopEmployee" 
                              :key="item" 
                              :value="item.id">
                              {{ item.name }}
                            </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> التاريخ  </label>
                    <div class="col-sm-9">
                      <input type="date" v-model="edit_date" class="form-control">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">المهنة</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_occupation" class="form-control">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">القسم الادارى</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_administrative_section" class="form-control">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">التقييم الحالى للموظف </label>
                    <div class="col-sm-9">
                      <select class="form-control form-select" v-model="edit_evaluation_now">
                          <option value="جيد">جيد</option>
                          <option value="جيد جدا">جيد جدا</option>
                          <option value="ممتاز">ممتاز</option>
                          <option value="مقبول">مقبول</option>
                          <option value="غير مناسب">غير مناسب</option>
                      </select>
                    </div>
                </div>
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">التقييم بعد التدريب </label>
                    <div class="col-sm-9">
                      <select class="form-control form-select" v-model="edit_evaluation_training">
                          <option value="جيد">جيد</option>
                          <option value="جيد جدا">جيد جدا</option>
                          <option value="ممتاز">ممتاز</option>
                          <option value="مقبول">مقبول</option>
                          <option value="غير مناسب">غير مناسب</option>
                      </select>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> الاحتياج التدريبى له </label>
                    <div class="col-sm-9">
                      <textarea  v-model="edit_training_need" cols="2" rows="2" class="form-control"></textarea>
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> مدة التدريب </label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_training_time" class="form-control">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> مســــؤل التدريب </label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_training_officer" class="form-control">
                    </div>
                </div>
              </div>
                <div class="row mb-3" v-if="acount_type == 'hr'">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">مدير الموارد البشريه </label>
                    <div class="col-sm-9">
                      <select v-model="edit_hr" class="form-control form-select">
                        <option value=""></option>
                        <option value="تم الاعتماد">تم الاعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>     
                    <br>          
                    <textarea v-model="edit_refuse_hr" class="form-control" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                    </div>
                </div>




                <div v-if="employee_acount_type == 5  && edit_hr == 'تم الاعتماد'">
                  <div class="row mb-3" v-if="acount_type == 'general_director'">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">المدير العام</label>
                    <div class="col-sm-9">
                      <select v-model="edit_general_director" class="form-control">
                        <option value=""></option>
                        <option value="تم الاعتماد">تم الاعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>     
                    <br>          
                    <textarea v-model="edit_refuse_gd" class="form-control" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                    </div>
                  </div>
                </div>

                <div v-if="employee_acount_type < 5">
                  <div class="row mb-3" v-if="acount_type == 'general_director'">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">المدير العام</label>
                    <div class="col-sm-9">
                      <select v-model="edit_general_director" class="form-control">
                        <option value=""></option>
                        <option value="تم الاعتماد">تم الاعتماد</option>
                        <option value="رفض">رفض</option>
                    </select>     
                    <br>          
                    <textarea v-model="edit_refuse_gd" class="form-control" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                    </div>
                  </div>
                </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>





<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopUsers: [],
      loopData      : [],
      loopEmployee  : [],
      loopScores    : [],
      acount_type   : localStorage.getItem('acount_type'),
      user_id       : localStorage.getItem('user_id'),

      muazaf	            : "",
      date	              : "",
      occupation	        : "",
      administrative_section	: "",
      training_need	      : "",
      training_time	      : "",
      training_officer	  : "",
      evaluation_now      : "",
      evaluation_training : "",


      edit_id                 : "",
      edit_muazaf	            : "",
      edit_date	              : "",
      edit_occupation	        : "",
      edit_administrative_section	: "",
      edit_training_need	    : "",
      edit_training_time	    : "",
      edit_training_officer	  : "",
      edit_evaluation_now     : "",
      edit_evaluation_training: "",

      edit_hr                 : "",
      edit_refuse_hr          : "",
      edit_general_director   : "",
      edit_refuse_gd          : "",

      author: "",
      employee_acount_type  : "",
      
    }
  },

  mounted () {
      window.document.title = "تدريب موظف";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    getData(){
      axios.get(`${urlSite}/training`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
          this.loopUsers = res.data.users

        })
    
    },




    store  () {
      axios.post(`${urlSite}/store.training`, {
        author	            : localStorage.getItem("user_id"),
        muazaf	            : this.muazaf,
        date	              : this.date,
        occupation	        : this.occupation,
        administrative_section	: this.administrative_section,
        training_need	      : this.training_need,
        training_time	      : this.training_time,
        training_officer	  : this.training_officer,
        evaluation_now      : this.evaluation_now,
        evaluation_training : this.evaluation_training,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    

    // Get Data For Edit
    openEditModel (id, author, muazaf,	date,	occupation,	administrative_section,	training_need,	training_time,	training_officer,	evaluation_now, evaluation_training, hr, refuse_hr, general_director, refuse_gd, acount_type) {
      this.edit_id                  = id
      this.author                   = author
      this.edit_muazaf	            = muazaf
      this.edit_date	              = date
      this.edit_occupation	        = occupation
      this.edit_administrative_section = administrative_section
      this.edit_training_need	      = training_need
      this.edit_training_time	      = training_time
      this.edit_training_officer	  = training_officer
      this.edit_evaluation_now      = evaluation_now
      this.edit_evaluation_training = evaluation_training
      this.edit_hr                  = hr
      this.edit_refuse_hr           = refuse_hr
      this.edit_general_director    = general_director
      this.edit_refuse_gd           = refuse_gd
      this.employee_acount_type     = acount_type

    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.training`, {
        id	                : this.edit_id,
        muazaf	            : this.edit_muazaf,
        date	              : this.edit_date,
        occupation	        : this.edit_occupation,
        administrative_section	: this.edit_administrative_section,
        training_need	      : this.edit_training_need,
        training_time	      : this.edit_training_time,
        training_officer	  : this.edit_training_officer,
        evaluation_now      : this.edit_evaluation_now,
        evaluation_training : this.edit_evaluation_training,
        hr                  : this.edit_hr,
        refuse_hr           : this.edit_refuse_hr,
        general_director    : this.edit_general_director,
        refuse_gd           : this.edit_refuse_gd,
        acount_type : localStorage.getItem('acount_type'),

      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "training", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   