
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header"> طلبات الصيانه </h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>مقدم الطلب</th>
                <th>تفاصيل المعده</th>
                <th>بيان بالاعطال</th>
                <th>التاريخ</th>
                <th>مدير القسم</th>
                <th>الموارد البشرية</th>
                <th>المدير العام</th>
                <th>ملاحظــــات</th>
                <th>المرفقات</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> 
                  <span v-for="employee in loopUsers" :key="employee">
                    <span v-if="employee.id == item.muazaf">{{ employee.name }}</span>
                  </span>   
                </td>

                <td> {{ item.stomach_data }}  </td>
                <td> {{ item.statement }}  </td>
                <td> {{ item.date }}  </td>

                <td> 
                  <span v-if="item.direct_manager == 'تم الاعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span>
                  <br>
                  {{ item.reason_refuse_direct_manager }}
                </td>
                <td> 
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span>
                  <br>
                  {{ item.reason_refuse_hr }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span>
                  <br>
                  {{ item.reason_refuse_gd}}
                </td>
                <td> {{ item.note }}  </td>
                <td> 
                  <router-link :to="'/files/maintenance_request/' + item.id">
                    المرفقات 
                  </router-link>
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a class="btn-action"
                     data-toggle="modal" 
                     data-target="#uploadModal" 
                     @click="openUploadModel(item.id)">
                     <i class="fas fa-arrow-alt-up"></i>
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.author, item.stomach_data, item.muazaf, item.statement, item.date, item.note, item.direct_manager, item.reason_refuse_direct_manager, item.hr, item.reason_refuse_hr, item.general_director, item.reason_refuse_gd, item.acount_type)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->

    <!-- Upload File -->
    <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="uploadFile()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <div class="uploadFileBox">
                <label for="upload-file">
                  <i class="fal fa-cloud-upload"></i>
                  <strong>رفــــــــع ملـــف</strong>
                  <strong>PDF, ZIP, RAR, PNG, JPG, PNG, DOCX, XLSX, CSV</strong>
                  <input  type="file"  
                          class="form-control" 
                          id="upload-file" 
                          @change="uploadPicture" 
                          accept="pdf, zip, rar, png, jpg, gpeg, png, docx, xlsx, csv"
                  />
                </label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Upload File -->

    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> تفاصيل المعده </label>
                  <div class="col-sm-9">
                    <textarea v-model="stomach_data"  cols="3" rows="3" class="form-control"  placeholder="نوع السيارة - اللوحه - الموديل"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> بيان بالاعطال  </label>
                  <div class="col-sm-9">
                    <textarea v-model="statement"  cols="3" rows="3" class="form-control"  placeholder="البيان"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                  <div class="col-sm-9">
                    <textarea v-model="note" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden"  v-model="editID" >


              <div class="" v-if="author == user_id">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> تفاصيل المعده </label>
                  <div class="col-sm-9">
                    <textarea v-model="edit_stomach_data"  cols="3" rows="3" class="form-control"  placeholder="نوع السيارة - اللوحه - الموديل"></textarea>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> بيان بالاعطال  </label>
                    <div class="col-sm-9">
                      <textarea v-model="edit_statement"  cols="3" rows="3" class="form-control"  placeholder="البيان"></textarea>
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name"> ملاحظــــات </label>
                    <div class="col-sm-9">
                      <textarea v-model="edit_note" class="form-control" cols="3" rows="3"></textarea>
                    </div>
                </div>
              </div>


              <div class="row mb-3" v-if="acount_type == 'direct_manager'">
                  <label class="col-sm-3 col-form-label" for="basic-default-name"> مدير القسم </label>
                  <div class="col-sm-9">
                    <select v-model="edit_direct_manager" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                  </select>     
                  <br>          
                  <textarea v-model="edit_reason_refuse_direct_manager" class="form-control form-select" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                  </div>
              </div>

              <div v-if="employee_acount_type == 5 &&  edit_direct_manager == 'تم الاعتماد'">
                <div class="row mb-3" v-if="acount_type == 'hr'">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">مدير الموارد البشريه </label>
                  <div class="col-sm-9">
                    <select  v-model="edit_hr" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                  </select>     
                  <br>          
                  <textarea v-model="edit_reason_refuse_hr" class="form-control form-select" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                  </div>
                </div>
              </div>
              <div v-if="employee_acount_type < 5">
                <div class="row mb-3" v-if="acount_type == 'hr'">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">مدير الموارد البشريه </label>
                  <div class="col-sm-9">
                    <select  v-model="edit_hr" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                  </select>     
                  <br>          
                  <textarea v-model="edit_reason_refuse_hr" class="form-control form-select" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                  </div>
              </div>
              </div>

              <div v-if="employee_acount_type == 5  && edit_hr == 'تم الاعتماد'">
                <div class="row mb-3" v-if="acount_type == 'general_director'">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المدير العام</label>
                  <div class="col-sm-9">
                    <select v-model="edit_general_director" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                  </select>     
                  <br>          
                  <textarea v-model="edit_reason_refuse_gd" class="form-control form-select" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                  </div>
                </div>
              </div>
              <div v-if="employee_acount_type < 5">
                <div class="row mb-3" v-if="acount_type == 'general_director'">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المدير العام</label>
                  <div class="col-sm-9">
                    <select v-model="edit_general_director" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">تم الاعتماد</option>
                      <option value="رفض">رفض</option>
                  </select>     
                  <br>          
                  <textarea v-model="edit_reason_refuse_gd" class="form-control form-select" cols="2" rows="2" placeholder="سبب الرفض ان وجد"></textarea>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>
.form-check {
    display: inline-block;
    min-height: 1.434375rem;
    padding-left: 1.7em;
    margin-bottom: 0.125rem;
}

.modal-body input {
    padding: 5px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 20px;
}
</style>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopUsers: [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),
      user_id     : localStorage.getItem('user_id'),
      author      : "",

      stomach_data:"",
      muazaf: "",
      statement:"",
      date:"",
      note:"",


      edit_stomach_data:"",
      edit_muazaf: "",
      edit_statement:"",
      edit_date:"",
      edit_note:"",


      edit_direct_manager : "",
      edit_reason_refuse_direct_manager	: "",
      edit_hr : "",
      edit_reason_refuse_hr : "",
      edit_general_director : "",
      edit_reason_refuse_gd : "",

      employee_acount_type: "",

      // Upload Files
      row_id: null,
      uploadFilePicture : {
        picture: null,
        imageUrl: null,
        getPicture: null
      },
      
    }
  },

  mounted () {
      window.document.title = "طلبات الصيانه";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {


    openUploadModel(id) {
      this.row_id = id
    },

    uploadPicture(e){
      this.uploadFilePicture.picture = e.target.files[0];
      this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      this.uploadFilePicture.getPicture = e.target.files[0].name;
    },

    
    uploadFile  () {
      const formData = new FormData();
      formData.append("table",        "maintenance_request");
      formData.append("row",          this.row_id);
      formData.append("author",       localStorage.getItem("user_id"));
      formData.append("acount_type",  localStorage.getItem("acount_type"));
      formData.append("file",         this.uploadFilePicture.picture); 

      axios.post(`${urlSite}/upload.files`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },


    acceptRow(id, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'covenant',
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/maintenance.request`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopScores = res.data.scores
          this.loopData = res.data.data
          this.loopUsers = res.data.users
        })
    
    },

    store  () {
      axios.post(`${urlSite}/store.maintenance.request`, {
        author        : localStorage.getItem("user_id"),
        stomach_data  : this.stomach_data,
        muazaf     : localStorage.getItem("user_id"),
        statement     : this.statement,
        date          : this.date,
        note          : this.note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },



    // Get Data For Edit
    openEditModel (getID, author, stomach_data, muazaf, statement, date, note, direct_manager, reason_refuse_direct_manager, hr, reason_refuse_hr, general_director, reason_refuse_gd, acount_type) {
      this.editID      = getID
      this.author      = author
      this.edit_stomach_data = stomach_data
      this.edit_muazaf = muazaf
      this.edit_statement = statement
      this.edit_date      = date
      this.edit_note      = note

      this.edit_direct_manager = direct_manager
      this.edit_reason_refuse_direct_manager = reason_refuse_direct_manager
      this.edit_hr = hr
      this.edit_reason_refuse_hr = reason_refuse_hr
      this.edit_general_director = general_director
      this.edit_reason_refuse_gd = reason_refuse_gd
      this.employee_acount_type  = acount_type
      

    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.maintenance.request`, {
        id:this.editID, 
        stomach_data  : this.edit_stomach_data,
        muazaf        : localStorage.getItem("user_id"),
        statement     : this.edit_statement,
        date          : this.edit_date,
        note          : this.edit_note,

        direct_manager : this.edit_direct_manager,
        reason_refuse_direct_manager : this.edit_reason_refuse_direct_manager,
        hr : this.edit_hr,
        reason_refuse_hr : this.edit_reason_refuse_hr,
        general_director : this.edit_general_director,
        reason_refuse_gd : this.edit_reason_refuse_gd,
        acount_type      : localStorage.getItem('acount_type'),
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "maintenance_request", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   