
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header">وصف الوظيفة</h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>مقدم الطلب</th>
                <th>المهارات المطلوبه</th>
                <th>المؤهل العلمى </th>
                <th>سنوات الخبره </th>
                <th>المدير العام</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.username  }}  </td>
                <td> {{ item.required_skills }}  </td>
                <td> {{ item.qualification }}  </td>
                <td> {{ item.years_experience }}  </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  {{ item.reason_gd }}
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.author, item.required_skills, item.qualification, item.years_experience, item.general_director, item.reason_gd)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المهارات المطلوبه بالموظف</label>
                  <div class="col-sm-9">
                    <textarea class="form-control" cols="3" rows="3" v-model="required_skills"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المؤهل العلمى </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="qualification" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">عدد سنوات الخبره </label>
                  <div class="col-sm-9">
                    <input type="text" v-model="years_experience" class="form-control">
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">مدير القسم </label>
                  <div class="col-sm-9">
                    <textarea v-model="section_manager" class="form-control" cols="3" rows="3"></textarea>
                  </div>
              </div>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Update Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="edit_id" >


              <div v-if="author == user_id">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">المهارات المطلوبه بالموظف</label>
                  <div class="col-sm-9">
                    <textarea class="form-control" cols="3" rows="3" v-model="edit_required_skills"></textarea>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">المؤهل العلمى </label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_qualification" class="form-control">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">عدد سنوات الخبره </label>
                    <div class="col-sm-9">
                      <input type="text" v-model="edit_years_experience" class="form-control">
                    </div>
                </div>
              </div>


              <div v-if="acount_type == 'general_director'">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد المدير العام</label>
                  <div class="col-sm-9">
                    <select v-model="edit_general_director" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">اعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض</label>
                    <div class="col-sm-9">
                      <textarea v-model="edit_reason_gd" class="form-control form-select" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                    </div>
                </div>
              </div>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->






  </div> <!-- container -->   
</template>





<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss: [],
      loopData                : [],
      loopEmployee            : [],
      loopScores              : [],
      acount_type             : localStorage.getItem('acount_type'),
      user_id                 : localStorage.getItem('user_id'),

      required_skills         : "",
      qualification           : "",
      years_experience        : "",
      section_manager         : "",

      
      // Edit Row
      edit_id: null,
      edit_required_skills    : "",
      edit_qualification      : "",
      edit_years_experience   : "",
      edit_section_manager    : "",
      edit_general_director   : "",
      edit_reason_gd          : "",

      author : "",
      
    }
  },

  mounted () {
      window.document.title = "وصف الوظيفة";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getID(id) {
      this.editID = id
    },
    

    getData(){
      axios.get(`${urlSite}/job.description`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
        })
    
    },




    store  () {
      axios.post(`${urlSite}/store.job.description`, {
        author            : localStorage.getItem("user_id"),
        required_skills   : this.required_skills,
        qualification     : this.qualification,
        years_experience  : this.years_experience,
        section_manager   : this.section_manager,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    // Get Data For Edit
    openEditModel (id, author, required_skills, qualification, years_experience, general_director, reason_gd) {
      this.edit_id                  = id,
      this.author                   = author,
      this.edit_required_skills     = required_skills,
      this.edit_qualification       = qualification,
      this.edit_years_experience    = years_experience,
      this.edit_general_director    = general_director
      this.edit_reason_gd           = reason_gd
    }, // openEditModel


     

    update () {
      axios.post(`${urlSite}/update.job.description`, {
        id                : this.edit_id, 
        required_skills   : this.edit_required_skills,
        qualification     : this.edit_qualification,
        years_experience  : this.edit_years_experience,
        general_director  : this.edit_general_director,
        reason_gd         : this.edit_reason_gd,
        acount_type         : localStorage.getItem('acount_type'),

      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "job_description", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   