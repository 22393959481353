
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header"> مقابله شخصيه  </h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>رقم المستند</th>
                <th>اسم المتقدم</th>
                <th>الهاتف</th>
                <th>العمر</th>
                <th>الجنسيه</th>
                <th>المؤهل العلمى</th>
                <th>الخبرات</th>
                <th>المهنه</th>
                <th>الاداء الفنى</th>
                <th>الاداء النظرى</th>
                <th>الاختبارات العمليه</th>
                <th>الاختبارت النظريه</th>
                <th>اجاده الحاسب الالى</th>
                <th>اللغه الانجليزيه</th>
                <th>مدير القسم</th>
                <th>التوصيات</th>
                <th>مدير المباشر</th>
                <th>الموارد البشريه</th>
                <th>المدير العام</th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.id  }}  </td>
                <td> {{ item.username }}  </td>
                <td> {{ item.phone }}  </td>
                <td> {{ item.age }}  </td>
                <td> {{ item.nationality }}  </td>
                <td> {{ item.qualification }}  </td>
                <td> {{ item.experience }}  </td>
                <td> {{ item.occupation }}  </td>
                <td> {{ item.theoretical_performance }}  </td>
                <td> {{ item.technical_performanc }}  </td>
                <td> {{ item.practical_tests }}  </td>
                <td> {{ item.theoretical_tests }}  </td>
                <td> {{ item.computer }}  </td>
                <td> {{ item.english }}  </td>
                
                <td> 
                  <span v-for="employee in loopEmployee" :key="employee">
                    <span v-if="employee.id == item.department_manager">{{ employee.name }}</span>
                  </span>   
                </td>

                <td> {{ item.recommendations }}  </td>
                
                <td> 
                  <span v-if="item.direct_manager == 'تم الاعتماد'" class="badge bg-label-success">{{ item.direct_manager }} </span>
                  <span v-if="item.direct_manager == 'رفض'" class="badge bg-label-danger">{{ item.direct_manager }} </span> <br>
                  {{ item.direct_manager_reason }}
                </td>                
                <td> 
                  <span v-if="item.hr == 'تم الاعتماد'" class="badge bg-label-success">{{ item.hr }} </span>
                  <span v-if="item.hr == 'رفض'" class="badge bg-label-danger">{{ item.hr }} </span> <br>
                  {{ item.hr_reason }}
                </td>
                <td> 
                  <span v-if="item.general_director == 'تم الاعتماد'" class="badge bg-label-success">{{ item.general_director }} </span>
                  <span v-if="item.general_director == 'رفض'" class="badge bg-label-danger">{{ item.general_director }} </span> <br>
                  {{ item.general_director_reason }}
                </td>

                <td> {{ item.note }}  </td>
                <td>
                  <a class="btn-action" :href="'https://furncn.com/back-end/public/interview/'+item.file">
                    <i class="fas fa-arrow-alt-to-bottom"></i>
                  </a>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.username, item.phone, item.age, item.nationality, item.qualification, item.experience, item.occupation, item.technical_performanc, item.practical_tests, item.theoretical_performance, item.theoretical_tests, item.computer, item.english, item.department_manager, item.recommendations, item.direct_manager, item.direct_manager_reason, item.hr, item.hr_reason, item.general_director, item.general_director_reaso, item.acount_type)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div v-if="acount_type == 'hr'">
                <div class="row mb-3">
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">اسم المتقدم</label>
                      <input type="text" v-model="username" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الهاتف</label>
                      <input type="text" v-model="phone" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">العمر</label>
                      <input type="text" v-model="age" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الجنسية</label>
                      <input type="text" v-model="nationality" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">المؤهل العلمى</label>
                      <input type="text" v-model="qualification" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الخبرات</label>
                      <input type="text" v-model="experience" class="form-control">
                  </div>

                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name">تقييم   الاداء الفنى </label>
                      <select class="form-control form-select" v-model="technical_performanc">
                          <option value="جيد">جيد</option>
                          <option value="جيد جدا">جيد جدا</option>
                          <option value="ممتاز">ممتاز</option>
                          <option value="مقبول">مقبول</option>
                          <option value="غير مناسب">غير مناسب</option>
                      </select>
                  </div>
                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name">تقييم   الاداء النظرى </label>
                      <select class="form-control form-select" v-model="theoretical_performance">
                        <option value="جيد">جيد</option>
                        <option value="جيد جدا">جيد جدا</option>
                        <option value="ممتاز">ممتاز</option>
                        <option value="مقبول">مقبول</option>
                        <option value="غير مناسب">غير مناسب</option>
                      </select>
                  </div>
                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name">التوصيات</label>
                      <select class="form-control form-select" v-model="recommendations">
                        <option value="مناسب للتوظيف">مناسب للتوظيف</option>
                        <option value="غير مناسب">غير مناسب</option>
                      </select>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الاختبارات العمليه</label>
                      <textarea v-model="practical_tests" class="form-control" cols="2" rows="2"></textarea>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الاختبارت النظريه</label>
                      <textarea v-model="theoretical_tests" class="form-control" cols="2" rows="2"></textarea>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الحاسب الالى</label>
                      <input type="text" v-model="computer" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">اللغه الانجليزيه</label>
                      <input type="text" v-model="english" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">مدير القسم الطالب للموظف</label>
                      <select class="form-control" required v-model="department_manager">
                        <option value=""></option>
                        <option :value="item.id" v-for="item in loopEmployee" :key="item">{{ item.name }}</option>
                      </select>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">المهنه </label>
                      <input type="text" v-model="occupation" class="form-control">
                  </div>
                  
                  <div class="col-sm-12">
                      <label class="col-form-label" for="basic-default-name">ملف</label>
                      <input  type="file" 
                              @change="uploadPicture"
                              accept=".doc,.docx,.pdf,.txt,.xlsx,.rar,.zip"
                              class="form-control" 
                              id="basic-default-name" >
                  </div>
                  
                </div>
              </div>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="edit_id" />
              <div v-if="acount_type == 'hr'">
                <div class="row mb-3">
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">اسم المتقدم</label>
                      <input type="text" v-model="edit_username" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الهاتف</label>
                      <input type="text" v-model="edit_phone" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">العمر</label>
                      <input type="text" v-model="edit_age" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الجنسية</label>
                      <input type="text" v-model="edit_nationality" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">المؤهل العلمى</label>
                      <input type="text" v-model="edit_qualification" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الخبرات</label>
                      <input type="text" v-model="edit_experience" class="form-control">
                  </div>

                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name">تقييم   الاداء الفنى </label>
                      <select class="form-control form-select" v-model="edit_technical_performanc">
                        <option value="جيد">جيد</option>
                        <option value="جيد جدا">جيد جدا</option>
                        <option value="ممتاز">ممتاز</option>
                        <option value="مقبول">مقبول</option>
                        <option value="غير مناسب">غير مناسب</option>
                      </select>
                  </div>
                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name">تقييم   الاداء النظرى </label>
                      <select class="form-control form-select" v-model="edit_theoretical_performance">
                        <option value="جيد">جيد</option>
                        <option value="جيد جدا">جيد جدا</option>
                        <option value="ممتاز">ممتاز</option>
                        <option value="مقبول">مقبول</option>
                        <option value="غير مناسب">غير مناسب</option>
                      </select>
                  </div>
                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name">التوصيات</label>
                      <select class="form-control form-select" v-model="edit_recommendations">
                        <option value="مناسب للتوظيف">مناسب للتوظيف</option>
                        <option value="غير مناسب">غير مناسب</option>
                      </select>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الاختبارات العمليه</label>
                      <textarea v-model="edit_practical_tests" class="form-control" cols="2" rows="2"></textarea>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الاختبارت النظريه</label>
                      <textarea v-model="edit_theoretical_tests" class="form-control" cols="2" rows="2"></textarea>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">الحاسب الالى</label>
                      <input type="text" v-model="edit_computer" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">اللغه الانجليزيه</label>
                      <input type="text" v-model="edit_english" class="form-control">
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">مدير القسم الطالب للموظف</label>
                      <select v-model="edit_department_manager" required class="form-control">
                        <option ></option>
                        <option :value="item.id" v-for="item in loopEmployee" :key="item">{{ item.name }}</option>
                      </select>
                  </div>
                  <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name">المهنه </label>
                      <input type="text" v-model="edit_occupation" class="form-control">
                  </div>
                  <div class="col-sm-12">
                      <label class="col-form-label" for="basic-default-name">ملف</label>
                      <input type="file" @change="uploadPicture"  class="form-control" id="basic-default-name" >
                  </div>
                </div>
            </div>

            <div v-if="acount_type == 'direct_manager'">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد  مدير الادارة   </label>
                  <div class="col-sm-9">
                    <select v-model="direct_manager" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">اعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                    <div class="col-sm-9">
                      <textarea v-model="direct_manager_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                    </div>
                </div>
              </div>


              <div v-if="acount_type == 'hr' && direct_manager == 'تم الاعتماد'">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد الموارد البشرية</label>
                  <div class="col-sm-9">
                    <select v-model="hr" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">اعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                    <div class="col-sm-9">
                      <textarea v-model="hr_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                    </div>
                </div>
              </div>

              <div v-if="acount_type == 'general_director' && hr == 'تم الاعتماد'">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="basic-default-name">اعتماد المدير العام</label>
                  <div class="col-sm-9">
                    <select v-model="general_director" class="form-control form-select">
                      <option value=""></option>
                      <option value="تم الاعتماد">اعتماد</option>
                      <option value="رفض">رفض</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label" for="basic-default-name">سبب الرفض ان وجد</label>
                    <div class="col-sm-9">
                      <textarea v-model="general_director_reason" class="form-control" cols="3" rows="3" placeholder="سبب الرفض في حالة رفض الطلب"></textarea>
                    </div>
                </div>
              </div>




            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>




<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      loopData: [],
      loopEmployee: [],
      loopScores:[],
      acount_type : localStorage.getItem('acount_type'),


      username            	: "",
      phone	                : "",
      age	                  : "",
      nationality	          : "",
      qualification	        : "",
      experience	          : "",
      occupation           	: "",
      technical_performanc	: "",
      practical_tests	      : "",
      theoretical_performance	: "",
      theoretical_tests	    : "",
      computer	            : "",
      english	              : "",
      department_manager	  : "",
      recommendations	      : "",
      hr	                  : "",
      general_director	    : "",


      edit_id                 	  : "",
      edit_username            	  : " ",
      edit_phone	                : " ",
      edit_age	                  : "",
      edit_nationality	          : "",
      edit_qualification	        : "",
      edit_experience	            : "",
      edit_occupation           	: "",
      edit_technical_performanc	  : "",
      edit_practical_tests	      : "",
      edit_theoretical_performance	: "",
      edit_theoretical_tests	    : "",
      edit_computer	              : "",
      edit_english	              : "",
      edit_department_manager	    : "",
      edit_recommendations	      : "",

      direct_manager              : "",
	  	direct_manager_reason       : "",
      hr	                        : "",
      hr_reason	                  : "",
      general_director	          : "",
      general_director_reason     : "",

      employee_acount_type  : "",

      uploadFilePicture : {
        file: null,
        imageUrl: null,
        getPicture: null
      }, 


      
    }
  },

  mounted () {
      window.document.title = "المقابله الشخصية";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    uploadPicture(e){
      this.uploadFilePicture.file = e.target.files[0];
      this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      this.uploadFilePicture.getPicture = e.target.files[0].name;
    },

    acceptRow(id, column) {
      axios.post(`${urlSite}/update.apprpve.statement`, {
        id: id,
        table : 'interview',
        column: column,
        action : event.target.value
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });
    },

    getData(){
      axios.get(`${urlSite}/interview`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
        })
    
    },





    store  () {
      const formData = new FormData();
      formData.append("author"        , localStorage.getItem("user_id"));
      formData.append("username"      , this.username);
      formData.append("phone"         , this.phone);
      formData.append("age"           , this.age);
      formData.append("nationality"   ,  this.nationality);
      formData.append("qualification" , this.qualification);
      formData.append("experience"    , this.experience);
      formData.append("occupation"    , this.occupation);
      formData.append("technical_performanc"    , this.technical_performanc);
      formData.append("practical_tests"         , this.practical_tests);
      formData.append("theoretical_performance" , this.theoretical_performance);
      formData.append("theoretical_tests"       , this.theoretical_tests);
      formData.append("computer"      , this.computer);
      formData.append("english"       , this.english);
      formData.append("department_manager"      , this.department_manager);
      formData.append("recommendations"         , this.recommendations);
      formData.append("file"                    , this.uploadFilePicture.file);

      axios.post(`${urlSite}/store.interview`, formData).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    


    // Get Data For Edit
    openEditModel (id, username, phone, age, nationality, qualification, experience, occupation, technical_performanc, practical_tests, theoretical_performance, theoretical_tests, computer, english, department_manager, recommendations, direct_manager, direct_manager_reason, hr, hr_reason, general_director, general_director_reason, acount_type) {
        this.edit_id                      = id  
        this.edit_username                = username == null ? ' ' : username
        this.edit_phone                   = phone == null ? ' ' : phone
        this.edit_age                     = age == null ? ' ' : age
        this.edit_nationality             = nationality == null ? ' ' : nationality
        this.edit_qualification           = qualification == null ? ' ' : qualification
        this.edit_experience              = experience == null ? ' ' : experience
        this.edit_occupation              = occupation == null ? ' ' : occupation
        this.edit_technical_performanc    = technical_performanc == null ? ' ' : technical_performanc
        this.edit_practical_tests         = practical_tests == null ? ' ' : practical_tests
        this.edit_theoretical_performance = theoretical_performance == null ? ' ' : theoretical_performance
        this.edit_theoretical_tests       = theoretical_tests == null ? ' ' : theoretical_tests
        this.edit_computer                = computer == null ? ' ' : computer
        this.edit_english                 = english == null ? ' ' : english
        this.edit_department_manager      = department_manager == null ? ' ' : department_manager
        this.edit_recommendations         = recommendations == null ? ' ' : recommendations
        
        this.direct_manager                = direct_manager == null ? ' ' : direct_manager
        this.direct_manager_reason         = direct_manager_reason == null ? ' ' : direct_manager_reason
        this.hr	                           = hr == null ? ' ' : hr
        this.hr_reason	                   = hr_reason == null ? ' ' : hr_reason
        this.general_director	             = general_director == null ? ' ' : general_director
        this.general_director_reason       = general_director_reason == null ? ' ' : general_director_reason

        this.employee_acount_type          = acount_type
    }, // openEditModel


    update () {
      const formData = new FormData();
      formData.append("id"            , this.edit_id);
      formData.append("username"      , this.edit_username);
      formData.append("phone"         , this.edit_phone);
      formData.append("age"           , this.edit_age);
      formData.append("nationality"   ,  this.edit_nationality);
      formData.append("qualification" , this.edit_qualification);
      formData.append("experience"    , this.edit_experience);
      formData.append("occupation"    , this.edit_occupation);
      formData.append("technical_performanc"    , this.edit_technical_performanc);
      formData.append("practical_tests"         , this.edit_practical_tests);
      formData.append("theoretical_performance" , this.edit_theoretical_performance);
      formData.append("theoretical_tests"       , this.edit_theoretical_tests);
      formData.append("computer"      , this.edit_computer);
      formData.append("english"       , this.edit_english);
      formData.append("department_manager"      , this.edit_department_manager);
      formData.append("recommendations"         , this.edit_recommendations);

      formData.append("direct_manager"          , this.direct_manager);
      formData.append("direct_manager_reason"   , this.direct_manager_reason);
      formData.append("hr"                      , this.hr);
      formData.append("hr_reason"               , this.hr_reason);
      formData.append("general_director"        , this.general_director);
      formData.append("general_director_reason" , this.general_director_reason);

      formData.append("file"                    , this.uploadFilePicture.file);

      formData.append("acount_type"             , localStorage.getItem('acount_type'));
      axios.post(`${urlSite}/update.interview`, formData).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
          axios.post(`${urlSite}/destroy`, {
            id   : id, 
            table: "interview", 
          }).then(res => {
            this.getData();
          });
          Swal.fire(
            'حــــذف !',
            'تم حذف البيانات بنجاح',
            'success'
          )
        }
      })
    }, // onDelete







 }
}
</script>
   